import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  paymentDetails,
  setPaymentDetails,
  order_submition_amount,
} from "../../../assets/payment";
import OrderForm from "../services/OrderForm";
import Loading from "../services/Loading/Loading";
import Payment from "../services/Payment";
import data from "../../../assets/data";
import Alert from "../services/Alert/Alert";
import Card from "../../Card";
import axios from "axios";
import Login from "../services/Login/Login";
import Registration from "../services/Registration/Registration";
import { useNavigate } from "react-router-dom";

const CardSection = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [paymentURL, setPaymentURL] = useState(false);
  const [alertTrigger, setAlertTrigger] = useState(false);
  const [paymentTrigger, setPaymentTrigger] = useState(false);
  const [orderFormTrigger, setOrderFormTrigger] = useState(false);
  const [loginTrigger, setLoginTrigger] = useState(false);
  const [registrationTrigger, setRegistrationTrigger] = useState(false);
  const [form_data, setFormData] = useState({});
  const [submitionPaymentConfirmTrigger, setSubmitionPaymentConfirmTrigger] =
    useState(false);

  return (
    <>
      <Alert
        trigger={alertTrigger}
        setTrigger={setAlertTrigger}
        type="choice"
        heading={t("alert.login_required.heading")}
        message={t("alert.login_required.statement")}
        accept_btn_text={t("alert.login_required.login_btn")}
        cancel_btn_text={t("alert.login_required.reg_btn")}
        onCancel={() => {
          setAlertTrigger(false);
          setRegistrationTrigger(true);
        }}
        onAccept={() => {
          setAlertTrigger(false);
          setLoginTrigger(true);
        }}
      />
      <Alert
        trigger={submitionPaymentConfirmTrigger}
        setTrigger={setSubmitionPaymentConfirmTrigger}
        heading="Confirm Submition"
        message={`Submition fee of TZS: ${order_submition_amount}/=, required to submit this order`}
        type="choice"
        accept_btn_text="Accept"
        cancel_btn_text="Cancel"
        onCancel={() => setSubmitionPaymentConfirmTrigger(false)}
        onAccept={async () => {
          // Change popup window
          setSubmitionPaymentConfirmTrigger(false);
          setLoading(true);

          // Prepare payment data
          paymentDetails();
          const payment_order = setPaymentDetails(order_submition_amount, {
            header: "order_submition",
            data: form_data,
          });

          // Make payment request
          axios
            .post(data.backend_url + "/victech/payment/", payment_order, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                setPaymentTrigger(true);
                setPaymentURL(response.data.url);
              } else {
                setLoading(false);
                navigate("/fail");
              }
            })
            .catch((error) => {
              console.error("Server response error", error);
              navigate("/fail");
            });
        }}
      />
      <Login
        trigger={loginTrigger}
        setTrigger={setLoginTrigger}
        setRegistrationTrigger={setRegistrationTrigger}
      />
      <Registration
        trigger={registrationTrigger}
        setTrigger={setRegistrationTrigger}
        setLoginTrigger={setLoginTrigger}
      />
      <OrderForm
        formData={setFormData}
        trigger={orderFormTrigger}
        setTrigger={setOrderFormTrigger}
        confirmSubmition={setSubmitionPaymentConfirmTrigger}
      />
      <Loading trigger={loading} />
      <Payment
        setLoading={setLoading}
        trigger={paymentTrigger}
        setTrigger={setPaymentTrigger}
        url={paymentURL}
      />

      <div className="card_section py-5">
        <div className="container g-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg">
              <Card
                card_properties="bg-dark text-light text-center py-4 m-3"
                icon_properties="bi bi-cpu h1 text-secondary"
                header_properties="h3"
                header={t("home.card.first.heading")}
                text_properties="text-secondary lead"
                text={t("home.card.first.content")}
                btn_properties="blue-btn"
                onBtnClick={() =>
                  Cookies.get("user") || sessionStorage.getItem("user")
                    ? setOrderFormTrigger(true)
                    : setAlertTrigger(true)
                }
                btn_text={t("home.card.first.button")}
              />
            </div>
            <div className="col-lg">
              <Card
                card_properties="bg-secondary text-dark text-center py-4 m-3"
                icon_properties="bi bi-tools h1 text-dark"
                header_properties="h3 text-light"
                header={t("home.card.second.heading")}
                text_properties="lead"
                text={t("home.card.second.content")}
                btn_properties="btn-dark"
                onBtnClick={() =>
                  Cookies.get("user") || sessionStorage.getItem("user")
                    ? setOrderFormTrigger(true)
                    : setAlertTrigger(true)
                }
                btn_text={t("home.card.second.button")}
              />
            </div>
            <div className="col-lg">
              <Card
                card_properties="bg-dark text-light text-center py-4 m-3"
                icon_properties="bi bi-infinity h1 text-secondary"
                header_properties="h3"
                header={t("home.card.third.heading")}
                text_properties="text-secondary lead"
                text={t("home.card.third.content")}
                btn_properties="blue-btn"
                btn_link={data.phone_link}
                btn_text={t("home.card.third.button")}
                onBtnClick={() => {}} // Nothing to do with this button Click
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSection;
