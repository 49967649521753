export var market_variables = {
  order_quantity: 0,
  cart_order: {
    username: "",
    item: {
      name: "",
      model: "",
      quantity: 0,
      price: 0,
    },
  },
};

export const sections = [
  "all",
  "component",
  "sensor",
  "circuit",
  "tool",
  "gerber",
];
