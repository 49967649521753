import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import TopContacts from "../components/TopContacts/TopContacts";
import GallerySection from "../components/sections/gallery/GallerySection/GallerySection";
import UpButton from "../components/sections/services/UpButton/UpButton";

const Gallery = () => {
  return (
    <>
      <TopContacts />
      <Header />
      <GallerySection />
      <Footer />
      <UpButton />
    </>
  );
};

export default Gallery;
