import React from "react";

const Payment = (props) => {
  return props.trigger ? (
    <>
      {props.setLoading(false)}
      <div className="popup">
        <div className="popup-payment_container mx-5">
          <div className="x-close-icon">
            <i className="bi bi-x" onClick={() => props.setTrigger(false)} />
          </div>
          <div className="d-flex flex-column overflow-hidden">
            <h2>
              <i className="victech-gold">Vic</i>
              <strong className="victech-blue">Tech</strong>
              <small className="text-sm ms-1">Electronics</small>
            </h2>
            <iframe
              className="flex-fill"
              title="VicTeche Electronics Payment-Iframe"
              width="100%"
              height="400"
              src={props.url}
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    ""
  );
};

export default Payment;
