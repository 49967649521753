import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "flag-icon-css/css/flag-icons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useEffect } from "react";
import Aos from "aos";

import "./App.css";
import "aos/dist/aos.css";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Market from "./pages/Market";
import Gallery from "./pages/Gallery.js";
import FailPage from "./pages/FailPage.js";
import SuccessPage from "./pages/SuccessPage.js";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition.js";
import Cancel from "./components/sections/services/Response/Cancel.js";
import Profile from "./components/sections/user_profile/Profile/Profile.js";
import RequireAuth from "./components/sections/user_profile/auth/RequireAuth.js";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <Router>
      <Routes>
        {/* <Route path="test" element={<Loading trigger={true} />} /> */}
        <Route path="/" exact element={<Home />} />
        <Route
          path="profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route path="market" element={<Market />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="help" element={<Help />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsCondition />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="fail/:reason" element={<FailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
