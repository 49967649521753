import i18next from "i18next";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";

import "./Header.css";
import Login from "../sections/services/Login/Login";
import Registration from "../sections/services/Registration/Registration";

const Header = () => {
  const navigate = useNavigate();
  const [logTrigger, setLogTrigger] = useState(false);
  const [regTrigger, setRegTrigger] = useState(false);
  const [theme, setTheme] = useState({
    icon: "bi bi-moon",
    theme: "light",
  });
  const [t, i18n] = useTranslation("global");
  let user = Cookies.get("user") || sessionStorage.getItem("user") || null;
  let user_data =
    Cookies.get("user_data") || sessionStorage.getItem("user_data") || null;
  if (user_data) user_data = JSON.parse(user_data);

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const changeTheme = () => {
    if (theme.theme === "light") {
      setTheme({
        icon: "bi bi-brightness-high-fill",
        theme: "dark",
      });
    } else {
      setTheme({
        icon: "bi bi-moon",
        theme: "light",
      });
    }
  };

  const authenticated_profile = () => {
    const handleLogout = async () => {
      Cookies.remove("user");
      Cookies.remove("user_data");
      Cookies.remove('cart_list');
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("user_data");
      sessionStorage.removeItem('cart_list');
      navigate("/");
    };

    return (
      <>
        <li className="m-3 text-center">
          <h5 className="h5 text-light p-0 m-0">{user}</h5>
          <p className="p-0 m-0">{user_data.profile.profession}</p>
        </li>
        <hr className="my-1" />
        <hr className="my-1" />
        <li>
          <small className="nav-link">
            <i className="bi bi-person h5 me-2 ps-2"></i>
            <Link className="text-light" to={"/profile"}>
              {t("header.my_profile")}
            </Link>
          </small>
        </li>
        <hr className="my-1" />
        <li>
          <small className="nav-link" onClick={handleLogout}>
            <i className="bi bi-box-arrow-right h5 me-2 ps-2"></i>
            <span className="text-light">{t("header.sign_out")}</span>
          </small>
        </li>
        <hr className="my-1" />
        <li>
          <small className="nav-link">
            <i className="bi bi-question-circle h5 me-2 ps-2"></i>
            <Link className="text-light" to={"/help"}>
              {t("header.help")}
            </Link>
          </small>
        </li>
      </>
    );
  };

  const unauthenticated_profile = () => {
    return (
      <>
        <li>
          <small className="nav-link" onClick={() => setRegTrigger(true)}>
            <i className="bi bi-person-add h5 me-2 ps-2"></i>
            <span className="text-light">{t("header.sign_up")}</span>
          </small>
        </li>
        <hr className="my-1" />
        <li>
          <small className="nav-link" onClick={() => setLogTrigger(true)}>
            <i className="bi bi-box-arrow-in-right h5 me-2 ps-2"></i>
            <span className="text-light">{t("header.sign_in")}</span>
          </small>
        </li>
        <hr className="my-1" />
        <li>
          <small className="nav-link">
            <i className="bi bi-question-circle h5 me-2 ps-2"></i>
            <Link className="text-light" to={"/help"}>
              {t("header.help")}
            </Link>
          </small>
        </li>
      </>
    );
  };

  return (
    <>
      <Login
        trigger={logTrigger}
        setTrigger={setLogTrigger}
        setRegistrationTrigger={setRegTrigger}
      />
      <Registration
        trigger={regTrigger}
        setTrigger={setRegTrigger}
        setLoginTrigger={setLogTrigger}
      />

      <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
        <div className="container">
          <Link to={"/"} className="navbar-brand">
            <img
              src="./image/victech_horizontal.png"
              loading="lazy"
              height="35px"
              alt="VicTech Electronics"
            ></img>
          </Link>

          <div className="d-flex flex-row flex-lg-row-reverse">
            <div className="d-flex flex-row">
              <ul className="nav ms-auto">
                {/* Language */}
                <li className="nav-item">
                  <div className="dropdown">
                    <button
                      className="nav-link"
                      data-bs-toggle="dropdown"
                      data-bs-target="#language-dropdown"
                    >
                      <i className="bi bi-globe2 text-light" />
                    </button>
                    <ul
                      id="language-dropdown"
                      className="dropdown-menu bg-dark text-secondary"
                      aria-labelledby="language-menu"
                      role="menu"
                    >
                      <li className="h5 dropdown-item-text text-center text-light">
                        {t("header.language")}
                      </li>
                      <hr className="my-1" />
                      <hr className="my-1" />
                      <li onClick={() => changeLanguageHandler("sw")}>
                        <button
                          className={`nav-link text-${i18next.language === "sw" ? 'secondary' : 'light'}`}
                          disabled={i18next.language === "sw"}
                        >
                          <i
                            className="flag-icon flag-icon-tz mx-2"
                            style={{
                              opacity: i18next.language === "sw" ? 0.5 : 1,
                            }}
                          />
                          {t("header.swahili")}
                        </button>
                      </li>
                      <hr className="my-1" />
                      <li onClick={() => changeLanguageHandler("en")}>
                        <button
                          className={`nav-link text-${i18next.language === "en" ? 'secondary' : 'light'}`}
                          disabled={i18next.language === "en"}
                        >
                          <i
                            className="flag-icon flag-icon-gb mx-2"
                            style={{
                              opacity: i18next.language === "en" ? 0.5 : 1,
                            }}
                          />
                          {t("header.english")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>

                {/* Theme */}
                <li className="nav-item">
                  <button className="nav-link" onClick={changeTheme}>
                    <i className={`${theme.icon} text-light`} />
                  </button>
                </li>

                {/* Cart */}
                <li className="nav-item">
                  <div className="cart nav-link">
                    <i className="bi bi-cart3 text-light" />
                    <span className="cart-items">{Cookies.get("cart_list") || sessionStorage.getItem("cart_list") || 0}</span>
                  </div>
                </li>
              </ul>
            </div>

            <div>
              <span
                className="navbar-toggler custom-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navmenu"
                aria-controls="navmenu"
                aria-expanded="false"
                aria-label="Toggler navigation"
              >
                <i className="bi bi-three-dots-vertical text-light" />
              </span>

              <div className="collapse navbar-collapse" id="navmenu">
                <hr />
                <ul className="nav navbar-nav">
                  {/* Home */}
                  <li className="nav-item">
                    <NavLink
                      to={"/"}
                      activeclassname="active"
                      className="nav-link"
                    >
                      {t("header.home")}
                    </NavLink>
                  </li>

                  {/* Market */}
                  <li className="nav-item">
                    <NavLink
                      to={"/market"}
                      activeclassname="active"
                      className="nav-link"
                    >
                      {t("header.market")}
                    </NavLink>
                  </li>

                  {/* Gallery */}
                  <li className="nav-item">
                    <NavLink
                      to={"/gallery"}
                      activeclassname="active"
                      className="nav-link"
                    >
                      {t("header.gallery")}
                    </NavLink>
                  </li>

                  {/* Profile */}
                  <li className="nav-item">
                    <div className="dropdown">
                      <button
                        className="nav-link"
                        data-bs-toggle="dropdown"
                        data-bs-target="#profile-dropdown"
                        aria-expanded="false"
                      >
                        {t("header.profile")}
                      </button>
                      <ul
                        id="profile-dropdown"
                        className="dropdown-menu bg-dark text-secondary"
                        aria-labelledby="profile-menu"
                        role="menu"
                      >
                        {user
                          ? authenticated_profile()
                          : unauthenticated_profile()}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
