import React from 'react'

const DetailPicture = (props) => {

  const value = props.properties;

  return (
    <div id={value.section_id} className={value.section_properties}>
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{value.title}</h2>
        </div>
        <div className="row">
          <div className={`col-lg-6 ${value.contents_properties}`}>
            {value.contents}
          </div>
          <div
            className={`col-lg-6 ${value.images_properties}`}
            data-aos="zoom-in"
          >
            {value.images}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailPicture
