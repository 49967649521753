import React from "react";
import { useTranslation } from "react-i18next";

import "./Response.css";

const Fail = (props) => {
  const [t] = useTranslation("global");
  return (
    props.trigger && (
      <div
        className="response d-flex flex-column"
        onClick={() => props.setTrigger(false)}
      >
        <div className="response-container">
          <div className="icon text-danger">
            <i className="bi bi-x-circle" />
          </div>
          <h1>{t("response.fail")}</h1>
          <p>{props.message}</p>
        </div>
      </div>
    )
  );
};

export default Fail;
