import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import i18next from "i18next";

import "./SidebarFull.css";
import data from "../../../../../../assets/data";

const SidebarFull = (props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const [hovered, setHovered] = useState("");
  const [theme, setTheme] = useState("light");
  const [langDisplay, setLangDisplay] = useState("none");

  const user = Cookies.get("user") || sessionStorage.getItem("user");
  const user_data = JSON.parse(
    Cookies.get("user_data") ||
      sessionStorage.getItem("user_data") ||
      '{"user": {"username": "VictoniX", "email": "sample"}, "profile":{}}'
  );

  const logoutHandler = async () => {
    Cookies.remove("user");
    Cookies.remove("user_data");
    Cookies.remove("cart_list");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user_data");
    sessionStorage.removeItem("cart_list");
    navigate("/");
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const changeThemeHandler = () => {
    if (theme === "light") setTheme("dark");
    else setTheme("light");
  };

  return (
    props.trigger && (
      <div className="sidebar-full d-flex flex-column align-items-center">
        <i
          className="menu-icon bi bi-card-list"
          onClick={() => props.setSidebar("collapsed")}
        />

        {/* Logo */}
        <img
          className="logo"
          src="./image/victech_horizontal.png"
          loading="lazy"
          width="200px"
          alt="Pic."
        />

        {/* User details */}
        <div className="user d-flex flex-row align-items-center">
          <NavLink to={"/profile"}>
            <img
              className="image me-3"
              src={`${data.backend_url}/media-files/profile/${user}`}
              loading="lazy"
              width="60px"
              height="60px"
              alt="Pic."
            />
          </NavLink>
          <div className="d-flex flex-column">
            <strong className="name">{user}</strong>
            <small className="email">{user_data.user.email}</small>
          </div>
        </div>

        {/* TOP Menu */}
        <div className="menu top-menu d-flex flex-column align-items-start mt-4">
          {/* Home */}
          <NavLink
            to={"/"}
            className={`menu-item d-flex flex-row align-items-center ${
              hovered === "home" && "hovered"
            }`}
            onMouseEnter={() => setHovered("home")}
            onMouseLeave={() => setHovered("")}
          >
            <i className="bi bi-house-door me-3" />
            <span className="menu-label">{t("profile.sidebar.home")}</span>
          </NavLink>
          {/* Market */}
          <NavLink
            to={"/market"}
            className={`menu-item d-flex flex-row align-items-center ${
              hovered === "market" && "hovered"
            }`}
            onMouseEnter={() => setHovered("market")}
            onMouseLeave={() => setHovered("")}
          >
            <i className="bi bi-cart3 me-3" />
            <span className="menu-label">{t("profile.sidebar.market")}</span>
          </NavLink>

          {/* Gallery */}
          <NavLink
            to={"/gallery"}
            className={`menu-item d-flex flex-row align-items-center ${
              hovered === "gallery" && "hovered"
            }`}
            onMouseEnter={() => setHovered("gallery")}
            onMouseLeave={() => setHovered("")}
          >
            <i
              className="bi bi-film ms-1 me-3 mt-1"
              style={{ fontSize: "23px" }}
            />
            <span className="menu-label">{t("profile.sidebar.gallery")}</span>
          </NavLink>

          {/* Language */}
          <div className="d-flex flex-row">
            <div
              className={`menu-item d-flex flex-row align-items-center ${
                hovered === "language" && "hovered"
              }`}
              onMouseEnter={() => setHovered("language")}
              onMouseLeave={() => setHovered("")}
              onClick={() => setLangDisplay("block")}
            >
              <i
                className="bi bi-globe2 ms-1 me-3 mt-1"
                style={{ fontSize: "23px" }}
              />
              <span className="menu-label">
                {t("profile.sidebar.language.heading")}
              </span>
            </div>

            <div
              className={`language-list d-flex flex-column d-${langDisplay}`}
              onClick={() => setLangDisplay("none")}
            >
              <strong>{t("profile.sidebar.language.heading")}</strong>
              <hr className="my-1" />
              <hr className="m-0 mb-1" />
              <button
                className="nav-link d-flex flex-row"
                onClick={() => changeLanguageHandler("sw")}
                disabled={i18next.language === "sw"}
                style={{
                  opacity: i18next.language === "sw" ? 0.5 : 1,
                }}
              >
                <i className="flag-icon flag-icon-tz mx-2" />
                <span>{t("profile.sidebar.language.sw")}</span>
              </button>
              <hr className="my-1" />
              <button
                className="nav-link d-flex flex-row"
                onClick={() => changeLanguageHandler("en")}
                disabled={i18next.language === "en"}
                style={{
                  opacity: i18next.language === "en" ? 0.5 : 1,
                }}
              >
                <i className="flag-icon flag-icon-gb mx-2" />
                <span>{t("profile.sidebar.language.en")}</span>
              </button>
            </div>
          </div>

          {/* Theme */}
          <div
            className={`menu-item d-flex flex-row align-items-center ${
              hovered === "theme" && "hovered"
            }`}
            onMouseEnter={() => setHovered("theme")}
            onMouseLeave={() => setHovered("")}
            onClick={changeThemeHandler}
          >
            <i
              className={`bi bi-${
                theme === "light" ? "brightness-high" : "moon"
              } ms-1 me-3 mt-1`}
              style={{ fontSize: "23px" }}
            />
            <span className="menu-label">{t("profile.sidebar.theme")}</span>
          </div>
        </div>

        <div className="menu bottom-menu d-flex flex-column align-items-start">
          <div
            className={`menu-item d-flex flex-row align-items-center ${
              hovered === "logout" && "hovered"
            }`}
            onMouseEnter={() => setHovered("logout")}
            onMouseLeave={() => setHovered("")}
            onClick={logoutHandler}
          >
            <i className="bi bi-box-arrow-right ms-1 me-3" />
            <span className="menu-label">{t("profile.sidebar.logout")}</span>
          </div>
        </div>
      </div>
    )
  );
};

export default SidebarFull;
