import React from "react";
import { Link } from "react-router-dom";

const Accepted = (props) => {
  const serviceNameFormatHander = (service) => {
    let service_name;
    if (service === "simulation") service_name = "Simulation";
    else if (service === "circuit_design_analysis")
      service_name = "Circuit design and analysis";
    else if (service === "PCB_design_etching")
      service_name = "PCB designing and etching";
    else if (service === "PCB_assembling") service_name = "PCB assembling";
    else if (service === "firmware_programming")
      service_name = "Firmware programing";
    else if (service === "enclosure_3D_design")
      service_name = "Enclosure designing";
    else if (service === "software_system") service_name = "Software system";

    return service_name;
  };

  return (
    props.trigger && (
      <div>
        <div
          className={`overview d-flex flex-row align-items-top justify-content-between px-3 ${
            props.project_index % 2 === 0 ? "even" : "odd"
          }`}
        >
          <span className="py-2 pe-2">{props.project_title}</span>
          <div className="right-aligned d-flex flex-row align-items-top">
            <strong className="inv-no py-2 px-2">{props.invoice_number}</strong>

            <div
              className={`progress-container py-2 ps-2 ${
                props.section !== "accepted" && "d-none"
              }`}
            >
              <progress
                className="progress-btn mt-1"
                onClick={() => props.onProgressClick()}
              />
            </div>
          </div>
        </div>
        <div
          className={`progress-details p-3 ${
            props.progress_view_value ? "d-block" : "d-none"
          }`}
        >
          <h5 className="heading fw-bold bg-secondary text-white p-2 mb-2">
            Progess of the project
          </h5>

          {props.progress !== undefined &&
            props.progress.map((progress) => (
              <>
                <div className="service d-flex flex-row justify-content-between align-items-start px-5">
                  <small>{serviceNameFormatHander(progress.service)}</small>
                  <div className="right-aligned d-flex flex-row">
                    <input
                      className="me-5"
                      type="checkbox"
                      checked={progress.status}
                    />
                    {progress.progress_file ? (
                      <Link
                        to={progress.download_link}
                        className={`bi bi-download ${
                          progress.progress_file && "active"
                        }`}
                      />
                    ) : (
                      <i
                        className={`bi bi-tags ${progress.status && "active"}`}
                      />
                    )}
                  </div>
                </div>
                <hr className="my-1" />
              </>
            ))}
        </div>
      </div>
    )
  );
};

export default Accepted;
