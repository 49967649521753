import React from "react";
import './Header.css'
import { Link } from "react-router-dom";
import data from "../../../../../assets/data";
import { useTranslation } from "react-i18next";

const Header = () => {
   const [t] = useTranslation('global')
    return (
      <>
        <div className="profile-header d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h1>{t('profile.dashboard')}</h1>
          <div className="social d-flex flex-row">
            <Link 
              to={data.our_social.whatsapp} 
              className="bi bi-whatsapp" 
            />
            <Link
              to={data.our_social.facebook}
              className="bi bi-facebook ms-2"
            />
            <Link
              to={data.our_social.twitter}
              className="bi bi-twitter-x ms-2"
            />
            <Link
              to={data.our_social.instagram}
              className="bi bi-instagram ms-2"
            />
          </div>
        </div>

        <hr />
      </>
    );
}

export default Header