import React from "react";
import MarketSection from "../components/sections/market/MarketSection/MarketSection";
import TopContacts from "../components/TopContacts/TopContacts";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import UpButton from "../components/sections/services/UpButton/UpButton";

const Market = () => {
  return (
    <>
      <TopContacts />
      <Header />
      <MarketSection />
      <Footer />
      <UpButton />
    </>
  );
};

export default Market;
