import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <div className={`card ${props.card_properties}`}>
      <div className={`container ${props.container_properties}`}>
        <img src={props.image} loading="lazy" alt="Pic." className={`${props.image_properties}`}></img>
        <i className={`${props.icon_properties}`}></i>
        <div className={`card-header ${props.header_properties}`}>
          {props.header}
        </div>
        <p className={`card-text ${props.text_properties}`}>{props.text}</p>
        <Link to={props.btn_link} onClick={() => props.onBtnClick()}>
          <button className={`btn ${props.btn_properties}`}>
            {props.btn_text}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Card;
