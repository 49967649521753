import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import "./Cart.css";
import data from "../../../../../../assets/data";
import Alert from "../../../../services/Alert/Alert";
import {
  paymentDetails,
  setPaymentDetails,
} from "../../../../../../assets/payment";
import Loading from "../../../../services/Loading/Loading";
import Payment from "../../../../services/Payment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cart = (props) => {
  const username =
    Cookies.get("user") || sessionStorage.getItem("user") || null;
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [trash, setTrash] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmTrigger, setConfirmTrigger] = useState(false);
  const [paymentTrigger, setPaymentTrigger] = useState(false);
  const [deleteAlertTrigger, setDeleteAlertTrigger] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setCartList(props.cart_list);
  }, [props.cart_list]);

  useEffect(() => {
    if (props.cart_list.length > 0) {
      props.modifyCartList(cartList);
    }
  }, [props, cartList]);

  const changeQuantityHandler = async (item, incremented) => {
    const unit_price = item.price / item.quantity;

    if (incremented) item.quantity += 1;
    else item.quantity -= 1;

    if (item.quantity === 0) {
      setTrash([{ name: item.name, model: item.model, detail: item.detail }]);
      setDeleteAlertTrigger(true);
      if (!deleted) item.quantity += 1;
    } else {
      item.price = unit_price * item.quantity;

      setCartList((prevCart) =>
        prevCart.map((cartItem) =>
          cartItem.name === item.name && cartItem.model === item.model
            ? { ...cartItem, ...item }
            : cartItem
        )
      );

      try {
        await axios.post(
          data.backend_url + "/profile/cart/add/",
          { username: username, item: item },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
      } catch (error) {
        console.error("Error updating item:", error);
      }
    }
  };

  const orderSubmitionHandler = async () => {
    paymentDetails();
    setLoading(true);
    setConfirmTrigger(false);

    const paymentData = setPaymentDetails(
      selectedItems.reduce((sum, item) => sum + item.price, 0),
      {
        header: "market",
        data: {
          username: username,
          order_list: selectedItems,
          order_price: selectedItems.reduce((sum, item) => sum + item.price, 0),
        },
      }
    );
    axios
      .post(
        // data.backend_url + "/market/resource/order/",
        data.backend_url + "/victech/payment/",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setPaymentURL(response.data.url);
          setPaymentTrigger(true);
        } else {
          navigate("/fail/Server error");
        }
      })
      .catch((error) => {
        console.error("Fail to place order", error);
      });
  };

  const selectedItemsHandler = (selection, action) => {
    if (selection === "all") {
      if (action) setSelectedItems(cartList);
      else setSelectedItems([]);
    } else {
      setSelectedItems((prevList) => {
        if (action) {
          const confirmed = prevList.some(
            (item) => JSON.stringify(item) === JSON.stringify(selection)
          );

          if (!confirmed) {
            return [...prevList, selection];
          }
        } else {
          const updatedList = prevList.filter(
            (item) => JSON.stringify(item) !== JSON.stringify(selection)
          );
          return updatedList;
        }

        return prevList;
      });
    }
  };

  const deleteTrashHandler = () => {
    if (selectedItems.length > 0) {
      setTrash(selectedItems.map(({ price, quantity, ...rest }) => rest));
      setDeleteAlertTrigger(true);
      if (deleted) setSelectedItems([]);
    }
  };

  return (
    props.trigger && (
      <>
        <Alert
          trigger={deleteAlertTrigger}
          setTrigger={setDeleteAlertTrigger}
          type="choice"
          heading="Delete"
          message="Are you sure you want to delete this item"
          accept_btn_text="Delete"
          cancel_btn_text="Cancel"
          onAccept={() => {
            axios
              .post(
                data.backend_url + "/profile/cart/delete/",
                { username: username, cart_list: trash },
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((response) => {
                if (response.status === 202) {
                  const newCart = cartList.filter(
                    (listItem) => !trash.includes(listItem)
                  );
                  setCartList((prevCart) => {
                    return newCart;
                  });
                }
              });

            setTrash([]);
            setDeleted(true);
            setDeleteAlertTrigger(false);
          }}
          onCancel={() => {
            setTrash([]);
            setDeleted(false);
            setDeleteAlertTrigger(false);
          }}
        />
        <Alert
          trigger={confirmTrigger}
          setTrigger={setConfirmTrigger}
          heading={t("market.popup.confirmation.heading")}
          message={`
            Are you sure you want to make this payment of ${new Intl.NumberFormat(
              "en-Tz",
              {
                style: "currency",
                currency: "TZS",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            ).format(
              selectedItems.reduce((sum, item) => sum + item.price, 0)
            )}/=
        `}
          type="choice"
          accept_btn_text={t("market.popup.confirmation.confirm_btn")}
          cancel_btn_text={t("market.popup.confirmation.cancel_btn")}
          onClose={() => setConfirmTrigger(false)}
          onCancel={() => setConfirmTrigger(false)}
          onAccept={() => orderSubmitionHandler()}
        />
        <Loading trigger={loading} />
        <Payment
          setLoading={setLoading}
          trigger={paymentTrigger}
          setTrigger={setPaymentTrigger}
          url={paymentURL}
        />
        <div className="market d-flex flex-column">
          <div className="header d-flex flex-column">
            <h3 className="title">Market Orders</h3>
            <div className="detail d-flex flex-row justify-content-between">
              <span>
                Cart{" "}
                <small className="text-secondary">
                  ({cartList.length} items)
                </small>
              </span>
              <span className="trash" onClick={deleteTrashHandler}>
                <i className="bi bi-trash3-fill" />
                <small>({selectedItems.length})</small>
              </span>
            </div>
          </div>

          <div className="body d-flex flex-column">
            {cartList.map((cartItem, index) => (
              <div
                key={index}
                className={`item d-flex flex-row justify-content-between align-items-center ${
                  index % 2 === 0 ? "even" : "odd"
                }`}
              >
                <div className="d-flex flex-row align-items-start">
                  <div className="order d-flex flex-row align-items-start">
                    <input
                      className="checkbox-resource mt-2"
                      type="checkbox"
                      checked={selectedItems.some(
                        (item) =>
                          item.name === cartItem.name &&
                          item.model === cartItem.model &&
                          item.detail === cartItem.detail
                      )}
                      onChange={(e) =>
                        selectedItemsHandler(cartItem, e.target.checked)
                      }
                    />

                    <img
                      className="item-image"
                      src={
                        data.backend_url +
                        "/media-files/image/resource/" +
                        `${cartItem.sku}-0`
                      }
                      loading="lazy"
                      alt="Pic."
                    />
                  </div>

                  <div className="d-flex flex-column flex-md-row">
                    <div className="detail d-flex flex-column mt-1">
                      <strong className="name">{cartItem.name}</strong>
                      <div>
                        <small className="fw-bold me-2">Model:</small>
                        <small>{cartItem.model}</small>
                      </div>
                      <div>
                        <small className="fw-bold me-2">Detail:</small>
                        <small>{cartItem.detail}</small>
                      </div>

                      <strong className="pb-5">
                        {new Intl.NumberFormat("en-Tz", {
                          style: "currency",
                          currency: "TZS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(cartItem.price)}{" "}
                        /=
                      </strong>
                    </div>

                    <div className="quantity d-flex flex-row">
                      <span
                        onClick={() => changeQuantityHandler(cartItem, false)}
                      >
                        -
                      </span>
                      <strong>{cartItem.quantity}</strong>
                      <span
                        onClick={() => changeQuantityHandler(cartItem, true)}
                      >
                        +
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className={`payment-section d-flex flex-row ${props.sidebar}`}>
              <input
                className="checkbox-all me-2"
                type="checkbox"
                checked={selectedItems.length === cartList.length}
                onChange={(e) => selectedItemsHandler("all", e.target.checked)}
              />
              <div className="section d-flex flex-row align-items-center justify-content-between">
                <div className="cost">
                  <span className="text-secondary me-1">Total:</span>
                  <strong>
                    {new Intl.NumberFormat("en-Tz", {
                      style: "currency",
                      currency: "TZS",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      selectedItems.reduce((sum, item) => sum + item.price, 0)
                    )}
                  </strong>
                </div>
                <div
                  className="payment-btn d-flex flex-row align-items-center"
                  onClick={() => setConfirmTrigger(true)}
                >
                  <strong>PURCHASE</strong>
                  <small>({selectedItems.length})</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Cart;
