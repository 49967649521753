import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./RightViewContainer.css";
import ChangePassword from '../ChangePassword/ChangePassword'
import EditProfile from "../EditProfile/EditProfile";
import DownloadInvoice from "../DownloadInvoice/DownloadInvoice";


const RightViewContainer = (props) => {
  const [t] = useTranslation('global')
  const [selected, setSelected] = useState(
    t("profile.right_view.menu.invoice")
  );
  const [hovered, setHovered] = useState('')

  return (
    <div className="right-view d-flex flex-column">
      <div className="header d-flex flex-column flex-md-row justify-content-between align-items-center mx-3">
        <h3 className="title">{selected}</h3>
        <div className="menu d-flex flex-row justify-content-center text-center">
          <div className="section invoice d-flex flex-column">
            <i
              className={`bi bi-file-earmark-pdf-fill mt-1 ${
                selected === t("profile.right_view.menu.invoice") && "active"
              }`}
              onMouseEnter={() => setHovered("invoice")}
              onMouseLeave={() => setHovered("")}
              onClick={() => setSelected(t("profile.right_view.menu.invoice"))}
            />
            <span className={`menu-label ${hovered !== "invoice" && "d-none"}`}>
              {t("profile.right_view.menu.invoice_hover")}
            </span>
          </div>
          <div className="section profile d-flex flex-column">
            <i
              className={`bi bi-person-vcard-fill ms-2 ${
                selected === t("profile.right_view.menu.profile") && "active"
              }`}
              style={{ fontSize: "25px" }}
              onMouseEnter={() => setHovered("profile")}
              onMouseLeave={() => setHovered("")}
              onClick={() => setSelected(t("profile.right_view.menu.profile"))}
            />
            <span className={`menu-label ${hovered !== "profile" && "d-none"}`}>
              {t("profile.right_view.menu.profile_hover")}
            </span>
          </div>
          <div className="section password d-flex flex-column">
            <i
              className={`bi bi-shield-lock-fill ms-2 mt-1 ${
                selected === t("profile.right_view.menu.password") && "active"
              }`}
              onMouseEnter={() => setHovered("password")}
              onMouseLeave={() => setHovered("")}
              onClick={() => setSelected(t("profile.right_view.menu.password"))}
            />
            <span
              className={`menu-label ${hovered !== "password" && "d-none"}`}
            >
              {t("profile.right_view.menu.password_hover")}
            </span>
          </div>
        </div>
      </div>

      <hr className="mb-0" />

      <div className="content">
        <DownloadInvoice
          trigger={selected === t("profile.right_view.menu.invoice")} project_list={props.project_list}
        />
        <ChangePassword
          trigger={selected === t("profile.right_view.menu.password")}
        />
        <EditProfile
          trigger={selected === t("profile.right_view.menu.profile")}
        />
      </div>
    </div>
  );
};

export default RightViewContainer;
