import React from "react";
import "./Button.css";

const Button = (props) => {
  return (
    <button
      className={`custom-btn btn ${props.color} ${props.size} ${props.width} ${props.margin}`}
      disabled={props.disabled}
      type={props.type}
      onClick={() => props.clicked(props.btn_value)}
    >
      {props.button}
    </button>
  );
};

export default Button;
