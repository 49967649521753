import React from "react";

import "./UpButton.css";
const UpButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return <i class="up-button bi bi-chevron-up" onClick={scrollToTop} />;
};

export default UpButton;
