import React, { useEffect, useState } from "react";

import "./Project.css";
import Pending from "./Pending";
import Accepted from "./Accepted";

const Project = (props) => {
  const [section, setSection] = useState("pending");
  const [projectList, setProjectList] = useState([]);
  const [progressViews, setProgressViews] = useState([]);

  useEffect(() => {
    setProjectList(props.project_list);

    const initialProgressViews = props.project_list.map((_, index) => ({
      index,
      value: false,
    }));
    setProgressViews(initialProgressViews);
  }, [props.project_list]);

  const getResponseHandler = (project_index, response) => {
    setProjectList((prevProjectList) => {
      return projectList.map((project, index) =>
        index === project_index ? { ...project, status: response } : project
      );
    });
  };

  return (
    props.trigger && (
      <div className="project d-flex flex-column">
        <div className="header d-flex flex-column">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3 className="title">Project</h3>
            <div className="menu d-flex flex-row">
              <span
                className={`menu-item ms-3 ${
                  section === "pending" && "active"
                }`}
                onClick={() => {
                  const closeProgressViews = Array.from(
                    projectList,
                    (_, index) => ({
                      index,
                      value: false,
                    })
                  );
                  setProgressViews(closeProgressViews);
                  setSection("pending");
                }}
              >
                Pending
              </span>
              <span
                className={`menu-item ${section === "accepted" && "active"}`}
                onClick={() => setSection("accepted")}
              >
                Accepeted
              </span>
            </div>
          </div>
          <span className="detail ps-3">
            {section === "accepted" ? "Accepted Projects" : "Pending Project"}
          </span>
        </div>

        <div className="body">
          {projectList.map((project, index) => (
            <div key={index}>
  
              <Pending
                trigger={
                  (project.status === "unknown" ||
                    project.status === "rejected") &&
                  section === "pending"
                    ? true
                    : false
                }
                getResponse={getResponseHandler}
                project_title={project.title}
                invoice_number={project.invoice_number}
                invoice_download_link={project.invoice_download_link}
                project_index={index}
                section={section}
              />
              <Accepted
                trigger={
                  project.status === "accepted" && section === "accepted"
                    ? true
                    : false
                }
                project_title={project.title}
                invoice_number={project.invoice_number}
                project_index={index}
                section={section}
                onProgressClick={() => {
                  setProgressViews((prev_views) =>
                    prev_views.map((item, i) =>
                      i === index ? { ...item, value: !item.value } : item
                    )
                  );
                }}
                progress={project.progress}
                progress_view_value={progressViews[index].value}
              />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Project;
