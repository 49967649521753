import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import TeamMember from "../../TeamMember";
import data from "../../../assets/data";

const Team = () => {
  const [t] = useTranslation("global");
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    axios
      .get(data.backend_url + "/victech/adminstrator/")
      .then((response) => {
        let members = [];
        for (let data of response.data) {
          let field = { id: data.pk, fields: data.fields };
          members.push(field);
        }
        setTeamMembers(members);
      })
      .catch((errror) => {});
  }, []);

  return (
    <section id="team" className="team">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>{t("home.team.heading")}</h2>
          <p>{t("home.team.content")}</p>
        </div>
        <div className="row">
          {teamMembers.map((teamMember) => (
            <TeamMember
              key={teamMember.id}
              properties={{
                picture: teamMember.fields.picture.split("/").pop().split(".")[0],
                name: teamMember.fields.name,
                position: teamMember.fields.position,
                whatsapp_link:
                  data.social.whatsapp + teamMember.fields.whatsapp,
                facebook_link:
                  data.social.facebook + teamMember.fields.facebook,
                twitter_link: data.social.twitter + teamMember.fields.twitter,
                instagram_link:
                  data.social.instagram + teamMember.fields.instagram,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
