import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import './Footer.css'
import data from "../../assets/data";

const Footer = () => {
  const [t] = useTranslation("global");
  let [email, setEmail] = useState("");
  const handleSubscription = async (e) => {
    e.preventDefault();
    await axios.get(data.backend_url + "/victech/subscribe/" + email);
  };

  const working_hours = [
    {
      key: 1,
      title: t("footer.working_hours.mon-fri"),
      time: data.working_hours.mon_fri,
    },
    {
      key: 2,
      title: t("footer.working_hours.saturday"),
      time: data.working_hours.suturday,
    },
    {
      key: 3,
      title: t("footer.working_hours.sunday"),
      time: t("footer.working_hours.closed"),
    },
  ];

  const social_links = [
    { key: "whatsapp", link: data.our_social.whatsapp, icon: "bi bi-whatsapp" },
    { key: "facebook", link: data.our_social.facebook, icon: "bi bi-facebook" },
    { key: "twitter", link: data.our_social.twitter, icon: "bi bi-twitter-x" },
    {
      key: "instagram",
      link: data.our_social.instagram,
      icon: "bi bi-instagram",
    },
    { key: "youtube", link: data.our_social.youtube, icon: "bi bi-youtube" },
  ];

  return (
    <footer className="footer bg-dark text-light">
      <div className="footer-top">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <Link to={"/"} className="h3" style={{ color: "#bab9e4" }}>
                  <i className="victech-gold">Vic</i>
                  <b className="victech-blue">Tech </b>
                  <small>Electronics</small>
                </Link>
                <hr className="victech-blue"></hr>
                <p>
                  <Link to={data.location_link}>{data.location}</Link>
                  <br></br>
                  <Link to={data.phone_link}>
                    <strong>{t("footer.phone")}:</strong> {data.phone}
                  </Link>
                  <br></br>
                  <Link to={`mailto:${data.email}`}>
                    <strong>{t("footer.email")}:</strong> {data.email}
                  </Link>
                  <br></br>
                </p>

                <div className="social-links mt-3">
                  {social_links.map((social_link) => (
                    <Link key={social_link.key} to={social_link.link}>
                      <i className={social_link.icon} />
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 footer-links">
              <h4>{t("footer.working_hours.heading")}</h4>
              <hr className="victech-blue"></hr>
              <ul>
                {working_hours.map((hours) => (
                  <li key={hours.key} className="footer_link">
                    <i className="bi bi-clock" /> &nbsp;
                    <strong>{hours.title}</strong>: &nbsp;
                    <span>{hours.time}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg col-md footer-newsletter">
              <h4 className="victech-gold">{t("footer.newsletter.heading")}</h4>
              <hr className="victech-blue"></hr>
              <p>{t("footer.newsletter.content")}</p>
              <form onSubmit={handleSubscription}>
                <input
                  type="email"
                  placeholder={t("footer.newsletter.sample_email")}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <input
                  type="submit"
                  value={t("footer.newsletter.subscribe")}
                ></input>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright text-center">
        <small>
          &copy; 2023 <i className="victech-gold">Vic</i>{" "}
          <b className="victech-blue">Tech</b> Electronics, &nbsp;
          {t("footer.copywrite.content")}
        </small>
        <div>
          <small>
            <Link to={"/terms-conditions"} className="victech-blue">
              {t("footer.copywrite.terms")}
            </Link>
            &nbsp; | &nbsp;
            <Link to={"/privacy-policy"} className="victech-blue">
              {t("footer.copywrite.policy")}
            </Link>
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
