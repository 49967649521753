import React, { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import "./ChangePassword.css";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import data from "../../../../../../assets/data";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const [t] = useTranslation('global')
  const [message, setMessage] = useState({
    type: "",
    info: "",
  });
  const [formData, setFormData] = useState({
    username: Cookies.get("user") || sessionStorage.getItem("user"),
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const InputFieldHandler = (key, value) => {
    if (key === "current") setFormData({ ...formData, password: value });
    else if (key === "new") setFormData({ ...formData, new_password: value });
    else if (key === "confirm")
      setFormData({ ...formData, confirm_password: value });
  };

  const submitHandler = async () => {
    if (formData.new_password === formData.confirm_password) {
      let response = await axios.post(
        data.backend_url + "/profile/change-password/",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 202) {
        setMessage({
          type: "success",
          info: response.data.payload,
        });
      } else {
        setMessage({
          type: "error",
          info: response.data.payload,
        });
      }
    } else {
      setMessage({
        type: "error",
        info: "Password missmatch",
      });
    }
  };

  return (
    props.trigger && (
      <div className="change-password d-flex flex-column">
        <div className="header">{t("profile.right_view.password.heading")}</div>

        <div className="form d-flex flex-column">
          <InputField
            title={"current"}
            isPassword={true}
            label={t("profile.right_view.password.current")}
            instruction={t("profile.right_view.password.current_placeholder")}
            required={true}
            setValue={InputFieldHandler}
          />

          <InputField
            title={"new"}
            isPassword={true}
            label={t("profile.right_view.password.new")}
            instruction={t("profile.right_view.password.new_placeholder")}
            required={true}
            setValue={InputFieldHandler}
          />

          <InputField
            title={"confirm"}
            isPassword={true}
            label={t("profile.right_view.password.confirm")}
            instruction={t("profile.right_view.password.confirm_placeholder")}
            required={true}
            setValue={InputFieldHandler}
            warning={
              formData.confirm_password &&
              formData.new_password !== formData.confirm_password &&
              t("profile.right_view.password.missmatch")
            }
          />

          <p className={`${message.type} text-center m-0 mt-3`}>
            {message.info}
          </p>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              button={t("profile.right_view.password.button")}
              color={"blue"}
              width={"half"}
              clicked={submitHandler}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ChangePassword;
