import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import i18next from "i18next";

import "./SidebarCollapsed.css";
import data from "../../../../../../assets/data";

const SidebarCollapsed = (props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const [hovered, setHovered] = useState("");
  const [theme, setTheme] = useState("light");
  const [langDisplay, setLangDisplay] = useState("none");
  const user = Cookies.get("user") || sessionStorage.getItem("user");

  const logoutHandler = async () => {
    Cookies.remove("user");
    Cookies.remove("user_data");
    Cookies.remove('cart_list');
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user_data");
    sessionStorage.removeItem('cart_list');
    navigate("/");
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const changeThemeHandler = () => {
    if (theme === "light") setTheme("dark");
    else setTheme("light");
  };

  return (
    props.trigger && (
      <>
        <div className="sidebar-collapsed d-flex flex-column align-items-center">
          <i
            className="menu-icon bi bi-card-list"
            onClick={() => props.setSidebar("full")}
          />
          <img className="logo mb-3" src="./image/logo.png" width="50px" loading="lazy" alt="Pic."/>
          <NavLink to={"/profile"}>
            <img
              className="user-image"
              src={`${data.backend_url}/media-files/profile/${user}`}
              loading="lazy"
              width="55px"
              height="55px"
              alt="Pic."
            />
          </NavLink>

          <div className="menu top-menu d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
              <NavLink
                to={"/"}
                className="bi bi-house-door mb-1"
                onMouseEnter={() => setHovered("home")}
                onMouseLeave={() => setHovered("")}
              />
              <span
                className={`menu-label mt-1 ${hovered !== "home" && "d-none"}`}
              >
                {t("profile.sidebar.home")}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <NavLink
                to={"/market"}
                className="bi bi-cart3 mb-1"
                onMouseEnter={() => setHovered("market")}
                onMouseLeave={() => setHovered("")}
              />
              <span
                className={`menu-label ${hovered !== "market" && "d-none"}`}
              >
                {t("profile.sidebar.market")}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <NavLink
                to={"/gallery"}
                className="bi bi-film ps-1 pt-1"
                style={{ fontSize: "23px" }}
                onMouseEnter={() => setHovered("gallery")}
                onMouseLeave={() => setHovered("")}
              />
              <span
                className={`menu-label mt-1 ${
                  hovered !== "gallery" && "d-none"
                }`}
              >
                {t("profile.sidebar.gallery")}
              </span>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center">
                <i
                  className="bi bi-globe2 ps-1 pt-1"
                  style={{ fontSize: "23px" }}
                  onMouseEnter={() => setHovered("language")}
                  onMouseLeave={() => setHovered("")}
                  onClick={() => setLangDisplay("block")}
                />
                <span
                  className={`menu-label mt-1 ${
                    hovered !== "language" && "d-none"
                  }`}
                >
                  {t("profile.sidebar.language.heading")}
                </span>
              </div>

              <div
                className={`language-list d-flex flex-column d-${langDisplay}`}
                onClick={() => setLangDisplay("none")}
              >
                <strong>{t("profile.sidebar.language.heading")}</strong>
                <hr className="my-1" />
                <hr className="m-0 mb-1" />
                <button
                  className="nav-link d-flex flex-row"
                  onClick={() => changeLanguageHandler("sw")}
                  disabled={i18next.language === "sw"}
                  style={{
                    opacity: i18next.language === "sw" ? 0.5 : 1,
                  }}
                >
                  <i className="flag-icon flag-icon-tz mx-2" />
                  <span>{t("profile.sidebar.language.sw")}</span>
                </button>
                <hr className="my-1" />
                <button
                  className="nav-link d-flex flex-row"
                  onClick={() => changeLanguageHandler("en")}
                  disabled={i18next.language === "en"}
                  style={{
                    opacity: i18next.language === "en" ? 0.5 : 1,
                  }}
                >
                  <i className="flag-icon flag-icon-gb mx-2" />
                  <span>{t("profile.sidebar.language.en")}</span>
                </button>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center">
              <i
                className={`bi bi-${
                  theme === "light" ? "brightness-high" : "moon"
                } ps-1 pt-1`}
                style={{ fontSize: "23px" }}
                onMouseEnter={() => setHovered("theme")}
                onMouseLeave={() => setHovered("")}
                onClick={changeThemeHandler}
              />
              <span
                className={`menu-label mt-1 ${hovered !== "theme" && "d-none"}`}
              >
                {t("profile.sidebar.theme")}
              </span>
            </div>
          </div>

          <div className="menu bottom-menu d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
              <i
                className="bi bi-box-arrow-right ps-1"
                onMouseEnter={() => setHovered("logout")}
                onMouseLeave={() => setHovered("")}
                onClick={logoutHandler}
              />
              <span
                className={`menu-label ${hovered !== "logout" && "d-none"}`}
              >
                {t("profile.sidebar.logout")}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SidebarCollapsed;
