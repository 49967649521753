import React from "react";

const Detail = (props) => {
  const value = props.property;

  return (
    <>
      <div className="container mb-5 lead">
        <section className="section profile">
          <div className="text-center">
            <div className="card-body pt-3">
              <div className="tab-header victech-blue pt-2">
                <h2 className="underline">{value.title}</h2>
              </div>
              <hr />
              <div className="tab-content pt-2 lead">{value.content}</div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Detail;
