import React from "react"

const PictureDetail = (props) => {
  const value = props.properties;

  return (
    <div id={value.section_id} className={value.section_properties}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6" data-aos="zoom-in">
            <img
              src={value.image}
              loading="lazy"
              className={`section-image ${value.image_class}`}
              alt="Pic."
            ></img>
          </div>
          <div
            className={`col-lg-6 ${value.contents_properties}`}
            data-aos="fade-left"
          >
            {value.contents}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PictureDetail;
