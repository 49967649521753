import React from "react";
import { useTranslation } from "react-i18next";

import Showcase from "../components/Showcase";
import Contacts from "../components/Contacts";
import CardSection from "../components/sections/home/CardSection";
import PictureDetail from "../components/sections/home/PictureDetail";
import DetailPicture from "../components/sections/home/DetailPicture";
import Chats from "../components/sections/home/Chats";
import Team from "../components/sections/home/Team";
import TopContacts from "../components/TopContacts/TopContacts";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import UpButton from "../components/sections/services/UpButton/UpButton";

const Home = () => {
  sessionStorage.setItem("orderFormPopup", false);
  sessionStorage.setItem("loginFormPopup", true);
  sessionStorage.setItem("regFormPopup", false);

  const [t] = useTranslation("global");

  const about_details = (
    <div className="content pt-4 pt-lg-0">
      <h3>{t("home.about.heading1")}</h3>
      <p className="fst-italic">
        <big>
          <i className="victech-gold">Vic</i>
          <b className="victech-blue">Tech</b>
        </big>{" "}
        <small>Electronics, </small>
        {t("home.about.short_history")}
      </p>
      <h5>{t("home.about.heading2")}</h5>
      <ul>
        <li>
          <i className="bi bi-check-circle" />
          {t("home.about.service1")}
        </li>
        <li>
          <i className="bi bi-check-circle" />
          {t("home.about.service2")}
        </li>
        <li>
          <i className="bi bi-check-circle"></i>
          {t("home.about.service3")}
        </li>
      </ul>
      <p>
        {t("home.about.statement1")} <br />
        {t("home.about.statement2")}
      </p>
    </div>
  );

  const feature_details = (
    <>
      <ul className="nav nav-tabs flex-column">
        <li className="nav-item mt-2" data-aos="fade-up">
          <a
            className="nav-link active show"
            data-bs-toggle="tab"
            href="#tab-1"
          >
            <h4>{t("home.service.section1.title")}</h4>
            <p>{t("home.service.section1.content")}</p>
          </a>
        </li>
        <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
            <h4>{t("home.service.section2.title")}</h4>
            <p>{t("home.service.section2.content")}</p>
          </a>
        </li>
        <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="200">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
            <h4>{t("home.service.section3.title")}</h4>
            <p>{t("home.service.section3.content")}</p>
          </a>
        </li>
        <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="300">
          <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
            <h4>{t("home.service.section4.title")}</h4>
            <p>{t("home.service.section4.content")}</p>
          </a>
        </li>
      </ul>
    </>
  );

  const feature_images = (
    <>
      <div className="tab-content mt-4">
        <div className="tab-pane active show" id="tab-1">
          <figure>
            <img
              src="./image/circuit_design.jpg"
              loading="lazy"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="tab-pane" id="tab-2">
          <figure>
            <img
              src="./image/system_programming.jpg"
              loading="lazy"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="tab-pane" id="tab-3">
          <figure>
            <img src="./image/3D_design.jpg" loading="lazy" alt="" className="img-fluid" />
          </figure>
        </div>
        <div className="tab-pane" id="tab-4">
          <figure>
            <img
              src="./image/system_installation.jpg"
              loading="lazy"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </div>
    </>
  );

  return (
    <div className="homepage d-flex flex-column m-0">
      <TopContacts />
      <Header />
      <Showcase />
      <CardSection />
      <PictureDetail
        properties={{
          section_id: "about",
          section_properties: "about",
          row_properties: "",
          image: "./image/victech_on_wall.jpg",
          image_class: "img-fluid",
          contents_properties: "d-flex flex-column justify-contents-center",
          contents: about_details,
        }}
      />
      <Chats />
      <DetailPicture
        properties={{
          title: t("home.service.heading"),
          section_id: "features",
          section_properties: "features",
          row_properties: "",
          contents_properties: "mb-tg-0",
          contents: feature_details,
          images_propertise: "order-2 order-lg-1",
          images: feature_images,
        }}
      />
      <Team />
      <Contacts />
      <Footer />
      <UpButton />
    </div>
  );
};
export default Home;
