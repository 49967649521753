import React, { useState } from "react";
import ReactImageMagnify from "react-image-magnify";

import "./ViewResource.css";
import data from "../../../../assets/data";
import Button from "../../user_profile/components/Button/Button";
import InputField from "../../user_profile/components/InputField/InputField";

const ViewResource = ({ fields }) => {
  let images = [0, 1, 2, 3, 4, 5];
  const [seletedImage, setSelectedImage] = useState(0);
  const [showMagnified, setShowMagnified] = useState(false);

  return (
    <div className="container view-resource d-flex flex-column flex-md-row">
      <div className="left">
        <div className="large"
        onMouseEnter={() => setShowMagnified(true)}
        onMouseLeave={() => setShowMagnified(false)}
        >
          <ReactImageMagnify
            smallImage={{
              alt: "Wristwatch by Ted Baker London",
              isFluidWidth: true,
              src:
                data.backend_url +
                "/media-files/image/resource/" +
                `${fields.sku}-${seletedImage}`,
            }}
            largeImage={{
              src:
                data.backend_url +
                "/media-files/image/resource/" +
                `${fields.sku}-${seletedImage}`,
              width: 2000,
              height: 1200,
            }}
          />
        </div>
        <small className="text-between">Roll over the image to zoom in</small>
        <div className="small d-flex flex-row">
          {images.map((image) => (
            <img
              key={image}
              className="small-image"
              src={
                data.backend_url +
                "/media-files/image/resource/" +
                `${fields["sku"]}-${image}`
              }
              loading="lazy"
              alt="Resource Pic."
              onMouseEnter={() => setSelectedImage(image)}
            />
          ))}
        </div>
      </div>
      <div className="right d-flex flex-column">
        <div className={`product-data ${showMagnified ? "d-none" : "d-block"}`}>
          <div className="identities d-flex flex-column">
            <h1 className="name">{fields.name}</h1>
            <span className="model">
              <span className="title">Model</span>: {fields.model}
            </span>
            <span className="sku">
              <span className="title">SKU</span>: {fields.sku}
            </span>
            <span className="price">
              {new Intl.NumberFormat("en-Tz", {
                style: "currency",
                currency: "TZS",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(fields.price)}
            </span>
          </div>

          <div className="product-details d-flex flex-column">
            <span className="heading">About the product:</span>
            <span className="details">{fields.detail}</span>
          </div>

          <div className="market d-flex flex-column">
            <div className="stock">In stock: {fields.quantity} pics</div>
            <div className="quantity d-flex flex-row">
              <span className="qty-btn">-</span>
              <InputField type="number" />
              <span className="qty-btn">+</span>
            </div>

            <Button button="Add to cart" color="blue" size="full" />
            <Button button="Buy now" color="blue" size="full" />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ViewResource;
