const data = {
  // backend_url: "http://localhost:8000",
  // frontend_url: "http://localhost:3000",
  backend_url: "https://api.victechelectronics.com",
  frontend_url: "https://victechelectronics.com",

  phone: "+255745495996 / +255775495996",
  phone_link: "tel:+255745495996,+255775495996",
  email: "victechelectronics@gmail.com",
  street: "Mopac Street, Buguruni",
  region: "Dar es salaam, Tanzania",
  location: "Mopac Street, Buguruni, Dar es salaam",
  location_link: "https://maps.app.goo.gl/LkRXhhnSZcf38m1b7",
  location_embeded_link:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.4773833338604!2d39.24428961066743!3d-6.833222866814582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4b6ef6357393%3A0xe21a566943fb841e!2sVicTech%20Electronics!5e0!3m2!1sen!2stz!4v1694542793246!5m2!1sen!2stz",

  social: {
    whatsapp: "https://wa.me/",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
  },

  our_social: {
    whatsapp: "https://wa.me/+255745495996",
    facebook: "https://www.facebook.com/VicTech Electronics",
    twitter: "https://twitter.com/VicTech_Tz",
    instagram: "https://www.instagram.com/victech_electronics",
  },

  working_hours: {
    mon_fri: "09:00 - 21:00",
    suturday: "09:00 - 18:00",
  },
};

export default data;
