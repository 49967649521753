import React, { useEffect, useState } from "react";
import "./Alert.css";

const Alert = (props) => {
  const [feedback, setFeedback] = useState();
  useEffect(() => {}, [feedback]);

  let feedback_container;
  let button_container;
  if (props.type === "choice")
    button_container = (
      <>
        <span className="button" onClick={() => props.onAccept()}>
          {props.accept_btn_text}
        </span>
        <span className="button" onClick={() => props.onCancel()}>
          {props.cancel_btn_text}
        </span>
      </>
    );
  else if (props.type === "feedback") {
    feedback_container = (
      <div className="d-flex flex-column">
        <input
          className="input-feedback text-center"
          type={props.feedback_input_type}
          min={props.feedback_min_val}
          value={feedback}
          placeholder={props.feedback_placeholder}
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
        />
        <small className="text-danger mt-2">{props.erroMessage}</small>
      </div>
    );
    button_container = (
      <>
        <span
          className="button"
          onClick={() => {
            props.getFeedback(feedback);
            props.onRightSelection();
          }}
        >
          {props.right_btn_text}
        </span>
        <span
          className="button"
          onClick={() => {
            props.getFeedback(feedback);
            props.onLeftSelection();
          }}
        >
          {props.left_btn_text}
        </span>
      </>
    );
  }

  return props.trigger ? (
    <div className="alert d-flex flex-row justify-content-center align-items-center">
      <div className="alert-screen">
        <div className="x-close-icon">
          <i className="bi bi-x" onClick={() => props.setTrigger(false)} />
        </div>
        <h3 className="heading">{props.heading}</h3>
        <p className="lead">{props.message}</p>
        <div>{feedback_container}</div>
        <p className={`${props.response_type} mt-3`}>{props.response}</p>
        <div className="button-container">{button_container}</div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Alert;
