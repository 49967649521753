import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import "./Profile.css";
import data from "../../../../assets/data";
import Cards from "../sections/Cards/Cards";
import Header from "../sections/Header/Header";
import SidebarFull from "../components/Sidebar/SidebarFull/SidebarFull";
import SidebarCollapsed from "../components/Sidebar/SidebarColapsed/SidebarCollapsed";
import LeftViewContainer from "../sections/LeftView/LeftViewContainer/LeftViewContainer";
import RightViewContainer from "../sections/RightView/RightViewContainer/RightViewContainer";

const Profile = () => {
  const [sidebar, setSidebar] = useState("collapsed");
  const [selected, setSelected] = useState("project");
  const [cartList, setCartList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [accepetedProject, setAcceptedProject] = useState([]);
  const [billTotalCost, setBillTotalCost] = useState(0);
  const username =
    Cookies.get("user") || sessionStorage.getItem("user") || null;

  // From Cards / Cards.js
  useEffect(() => {
    axios
      .get(data.backend_url + "/project/order/" + username)
      .then((response) => {
        if (response.status === 202) {
          setProjectList(response.data);

          const accepted_projects = response.data.filter(
            (project) => project.status === "accepted"
          );
          setAcceptedProject(accepted_projects);

          const due_sum = response.data.reduce((sum, item) => {
            if (item.due !== undefined) {
              return sum + item.due;
            }
            return sum;
          }, 0);
          setBillTotalCost(due_sum);
        }
      })
      .catch((error) =>
        console.error("Fail to get project order data: ", error)
      );

  
    axios
      .get(data.backend_url + "/profile/cart/" + username)
      .then((response) => {
        const jsonString = response.data.replace(/'/g, '"');
        if (response.status === 202) {
          const cart_list = JSON.parse(jsonString);
          Cookies.set('cart_list', cart_list.length)
          sessionStorage.setItem('cart_list', cart_list.length);
          setCartList(cart_list);
        }
      })
      .catch((error) => {
        console.error(error);
      });
      
  });

  return (
    <div className="profile-main-view">
      <i
        className={`menu-icon bi bi-card-list d-sm-none ${
          sidebar === "full" && "d-none"
        }`}
        onClick={() => setSidebar("full")}
      />
      <div className="d-none d-sm-flex">
        <SidebarCollapsed
          trigger={sidebar === "collapsed" ? true : false}
          setSidebar={setSidebar}
        />
      </div>

      <SidebarFull
        trigger={sidebar === "full" ? true : false}
        setSidebar={setSidebar}
      />

      <div className={`main-view d-flex flex-column ${sidebar}`}>
        <Header />
        <Cards
          setSelected={setSelected}
          accepted_project={accepetedProject}
          bill_total_cost={billTotalCost}
          cart_list={cartList}
          project_list={projectList}
        />
        <div className="body d-flex flex-column flex-lg-row mt-5">
          <LeftViewContainer
            selected={selected}
            sidebar={sidebar}
            cart_list={cartList}
            project_list = {projectList}
            modifyCartList = {setCartList}
          />
          <RightViewContainer project_list={projectList} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
