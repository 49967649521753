import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import './GallerySection.css'
import data from "../../../../assets/data";
import DisplayMedia from "../DisplayMedia";

const GallerySection = () => {
  const [t] = useTranslation("global");
  const [media, setMedia] = useState([]);
  const [filter, setFilter] = useState([]);
  const [active, setActive] = useState("imagefile");
  const [view, setView] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [smallSearch, setSmallSearch] = useState(false);
  const sections = ["imagefile", "videofile"];

  useEffect(() => {
    const request1 = axios.get(
      data.backend_url + "/media-files/image-details/"
    );
    const request2 = axios.get(
      data.backend_url + "/media-files/video-details/"
    );

    Promise.all([request1, request2])
      .then((responses) => {
        let response_str1 = responses[0].data.replace(/\[|\]/g, "");
        let response_str2 = responses[1].data.replace(/\[|\]/g, "");

        let response_string = "";
        if (response_str1 && response_str2) {
          response_string = String(
            "[" + response_str1 + ", " + response_str2 + "]"
          );
        } else if (response_str1) {
          response_string = String("[" + response_str1 + "]");
        } else if (response_str2) {
          response_string = String("[" + response_str2 + "]");
        }

        let response = JSON.parse(response_string);

        setMedia(response);
        setFilter(JSON.parse(responses[0].data));
      })
      .catch((errror) => {
        console.error("Error while featching media data", errror);
      });
  }, []);


  const searchHandler = (searched_data) => {
    searched_data = searched_data.toLowerCase();
    const filtered_data = media.filter(
      (item) => item.fields.title.toLowerCase().includes(searched_data)
    );

    setActive("all");
    if (filtered_data) setFilter(filtered_data);
  };

  const handleFilter = async (category) => {
    const filtered_data = media.filter(
      (iterm) => iterm.model === `media.${category}`
    );

    setActive(category);
    if (category === "all") setFilter(media);
    else setFilter(filtered_data);
  };

  const displayMedia = (
    <>
      {filter.slice(view * 30, view * 30 + 30).map((file) => (
        <DisplayMedia
          key={file.pk}
          properties={{
            pk: file.pk,
            media_type: file.model,
            title: file.fields.title,
            description: file.fields.description,
          }}
        />
      ))}
    </>
  );

  return (
    <>
      <section id="gallery" className="gallery pt-2">
        <div className="container">
          <div className="top-section d-flex flex-row justify-content-between align-items-start">
            <div className="section-title" data-aos="fade-up">
              <h2>
                <i className="bi bi-film"></i>&nbsp;
                {t("gallery.heading")}
              </h2>
            </div>

            <div className="section-content d-flex flex-row">
              <div className="gallery-menu">
                <div
                  className="selected d-flex flex-row"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <i
                    class={`bi bi-caret-${
                      dropdown ? "down" : "right"
                    }-fill pe-1`}
                  />
                  {t(`gallery.selection.${active}`)}
                </div>

                <div
                  className={`dropdown ${
                    dropdown ? "d-flex" : "d-none"
                  } justify-content-center`}
                >
                  <ul
                    className="dropdown-menu-list d-flex flex-column align-items-start"
                    data-aos="fade-down"
                    data-aos-delay="100"
                  >
                    {sections.map((section) => (
                      <li
                        key={section}
                        className={
                          active === section ? "filter-active" : undefined
                        }
                        onClick={() => handleFilter(section)}
                      >
                        {t(`gallery.selection.${section}`)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="search">
                <div className="d-none d-md-flex flex-row align-items-center">
                  <input
                    className="gallery-search-input text-center"
                    type="text"
                    placeholder={t("gallery.search.placeholder")}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button
                    className="btn btn-sm gallery-search-btn"
                    onClick={() => searchHandler(searchValue)}
                  >
                    {t("gallery.search.button")}
                  </button>
                </div>

                <div>
                  <div
                    className={`small-screen-search ${
                      smallSearch ? "d-flex" : "d-none"
                    } flex-row justify-content-between`}
                  >
                    <input
                      className="input text-center"
                      type="text"
                      placeholder={t("gallery.search.placeholder")}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button
                      className="btn btn-sm"
                      onClick={() => {
                        setSmallSearch(false);
                        searchHandler(searchValue);
                      }}
                    >
                      {t("gallery.search.button")}
                    </button>
                    <i
                      className="close bi bi-x"
                      onClick={() => setSmallSearch(false)}
                    />
                  </div>
                  <i
                    className="search-icon d-flex d-md-none bi bi-search"
                    onClick={() => setSmallSearch(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-0"/>

          <div
            className="row media-container"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            {displayMedia}

            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn border border-5 m-2"
                onClick={() => {
                  let value = view - 1;
                  if (value < 0) value = 0;
                  setView(value);
                }}
              >
                {t("gallery.buttons.prev")}
              </button>
              <button
                className="btn border border-5 m-2"
                onClick={() => {
                  let value = view + 1;
                  let limit = filter.length / 30;
                  if (value > limit) value--;
                  setView(value);
                }}
              >
                {t("gallery.buttons.next")}
              </button>
            </div>
          </div>

          <p className="text-center lead mt-5 mb-0">{t("gallery.note")}</p>
        </div>
      </section>
    </>
  );
};

export default GallerySection;
