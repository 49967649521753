import React from "react";
import Success from "../components/sections/services/Response/Success";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return <Success trigger={true} setTrigger={goBack} />;
};

export default SuccessPage;
