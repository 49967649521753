import React from "react";


import './TopContacts.css'
import data from "../../assets/data";

const TopContacts = () => {
  return (
    <div className="container mt-0">
      <small className="d-md-flex my-1">
        <a className="nav-link" href={data.phone_link}>
          <strong>
            <span className="victech-blue">
              <i className="bi bi-phone mx-2" />
            </span>
            {data.phone}
          </strong>
        </a>
        <a className="nav-link ms-auto" href={data.location_link}>
          <strong>
            <span className="victech-gold">
              <i className="bi bi-geo-alt-fill mx-2" />
            </span>
            {data.location}
          </strong>
        </a>
      </small>
    </div>
  );
};

export default TopContacts;
