import React from "react";
import "./Card.css";

const Card = (props) => {
  return (
    <div
      className={`card d-flex flex-column ${
        props.isCenterCard ? "center-card" : "side-card"
      }`}
      onClick={() => props.setSelection(props.card_selection)}
    >
      <div className="top d-flex flex-row justify-content-between mx-5 my-3">
        <div className="titles d-flex flex-column">
          <strong className="title-value">{props.title_value}</strong>
          <p className="title m-0">{props.title}</p>
        </div>
        <i className={`icon bi bi-${props.icon}`} />
      </div>
      <hr className="line m-1" />
      <div className="bottom d-flex flex-row justify-content-end align-items-center me-4 mb-2">
        <div className="details me-4">
          <strong className="detail-value me-2">
            {props.first_detail_value}
          </strong>
          <small className="detail">{props.first_detail}</small>
        </div>
        <div className="details">
          <strong className="detail-value me-2">
            {props.sec_detail_value}
          </strong>
          <small className="detail">{props.sec_detail}</small>
        </div>
      </div>
    </div>
  );
};

export default Card;
