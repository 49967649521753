// import axios from 'axios';
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import data from "../../../../assets/data";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [t] = useTranslation("global");
  let navigate = useNavigate();
  let [message, setMessage] = useState({
    info: "",
    type: "",
  });
  let [password_values, setPasswordValue] = useState({
    password_input_type: "password",
    password_visibility_icon: "bi bi-eye",
  });
  let [login_data, setLoginData] = useState({
    username: "",
    password: "",
    remeber: false,
  });

  const handlePasswordVisibility = async () => {
    if (password_values.password_input_type === "password") {
      setPasswordValue({
        password_input_type: "text",
        password_visibility_icon: "bi bi-eye-slash",
      });
    } else {
      setPasswordValue({
        password_input_type: "password",
        password_visibility_icon: "bi bi-eye",
      });
    }
  };

  let handleLogin = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        data.backend_url + "/profile/login/",
        login_data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let response_data = JSON.stringify(response.data.payload);

      if (response.status === 202) {
        if (login_data.remeber) {
          Cookies.set("user", login_data.username);
          Cookies.set("user_data", response_data);
        } else {
          sessionStorage.setItem("user", login_data.username);
          sessionStorage.setItem("user_data", response_data);
        }
        navigate("/profile");
      } else {
        setMessage({
          info: "Fail to login, Check username or password",
          type: "error",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        info: "Sorry! Fail due to server response error",
      });
    }
  };

  return props.trigger ? (
    <div className="popup">
      <section className="section d-block d-min-flex align-items-center py-5">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="x-close-icon my-0 py-0">
                  <i
                    className="bi bi-x"
                    onClick={() => props.setTrigger(false)}
                  />
                </div>
                <div className="pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    {t("form.login.heading")} &nbsp;
                    <Link to="/">
                      <span className="victech-gold">Vic</span>
                      <b className="victech-blue">Tech</b>
                    </Link>
                  </h5>
                  <p className="text-center">
                    All about <span className="victech-blue">Electronics</span>
                  </p>
                </div>

                <form
                  onSubmit={handleLogin}
                  className="form row g-3 needs-validation mb-3 mx-2"
                >
                  <div className="col-12">
                    <p className="form-label">{t("form.login.username")}</p>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      autoComplete="on"
                      value={login_data.username}
                      required
                      onChange={(e) => {
                        setMessage("");
                        setLoginData({
                          ...login_data,
                          username: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <p className="form-label">{t("form.login.password")}</p>
                    <div className="input-group has-validation">
                      <input
                        type={password_values.password_input_type}
                        className="form-control"
                        name="password"
                        value={login_data.password}
                        required
                        onChange={(e) => {
                          setMessage("");
                          setLoginData({
                            ...login_data,
                            password: e.target.value,
                          });
                        }}
                      />
                      <span className="input-group-text">
                        <i
                          className={password_values.password_visibility_icon}
                          onClick={handlePasswordVisibility}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="col-12 d-flex flex-row justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember"
                        onChange={(e) => {
                          setMessage("");
                          setLoginData({
                            ...login_data,
                            remeber: e.target.checked,
                          });
                        }}
                      />
                      <p className="form-check-label">
                        {t("form.login.remember")}
                      </p>
                    </div>
                    <Link to={""}>{t("form.login.password_reset")}</Link>
                  </div>

                  <div className="col-12">
                    <p className={`${message.type} text-center mb-0`}>
                      {message.info}
                    </p>
                  </div>

                  <div className="col-12">
                    <button className="btn btn-lg text-light w-100">
                      {t("form.login.button")}
                    </button>
                  </div>
                  <div className="col-12">
                    <p className="small mb-0">
                      {t("form.login.info")} &nbsp;
                      <span
                        className="link victech-blue"
                        onClick={() => {
                          props.setTrigger(false);
                          props.setRegistrationTrigger(true);
                        }}
                      >
                        {t("form.login.link")}
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    ""
  );
};

export default Login;
