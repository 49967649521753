import React from "react";
import Fail from "../components/sections/services/Response/Fail";
import { useNavigate, useParams } from "react-router-dom";

const FailPage = () => {
  const { reason } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Fail
      trigger={true}
      setTrigger={goBack}
      message={decodeURIComponent(reason)}
    />
  );
};

export default FailPage;
