import React, { useState } from "react";
import data from "../assets/data";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const [t] = useTranslation("global");
  let [message, setMessage] = useState({
    type: "",
    info: "",
  });
  let [form_data, setFormData] = useState({
    sender_name: "",
    sender_email: "",
    subject: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        data.backend_url + "/victech/email/",
        form_data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 202) {
        setMessage({
          type: "success",
          info: response.data.payload,
        });
      } else {
        setMessage({
          type: "error",
          info: response.data.payload,
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        info: "Sorry! Fail due to server response error",
      });
    }
  };

  return (
    <div id="contact" className="contact section-bg py-3">
      <div className="container">
        <div className="section-title text-center" data-aos="fade-up">
          <h2>{t("home.contact.heading")}</h2>
        </div>

        <div className="row">
          <div
            className="col-lg-5 d-flex align-items-stretch"
            data-aos="fade-right"
          >
            <div className="info">
              <div className="address">
                <Link to={data.location_link}>
                  <i className="bi bi-geo-alt"></i>
                  <h4>{t("home.contact.direction.location")}:</h4>
                  <p>{data.location}</p>
                </Link>
              </div>

              <div className="email">
                <Link to={`mailto:${data.email}`}>
                  <i className="bi bi-envelope"></i>
                  <h4>{t("home.contact.direction.email")}:</h4>
                  <p>{data.email}</p>
                </Link>
              </div>

              <div className="phone">
                <Link to={data.phone_link}>
                  <i className="bi bi-phone"></i>
                  <h4>{t("home.contact.direction.call")}:</h4>
                  <p>{data.phone}</p>
                </Link>
              </div>

              <iframe
                title="Contact Iframe title"
                className="map"
                src={data.location_embeded_link}
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div
            className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
            data-aos="fade-left"
          >
            <form className="email-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  {t("home.contact.message.name")}
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setFormData({
                        ...form_data,
                        sender_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-6 mt-3 mt-md-0">
                  {t("home.contact.message.email")}
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setFormData({
                        ...form_data,
                        sender_email: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="form-group mt-3">
                {t("home.contact.message.subject")}
                <input
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setFormData({ ...form_data, subject: e.target.value })
                  }
                ></input>
              </div>
              <div className="form-group mt-3">
                {t("home.contact.message.message")}
                <textarea
                  className="form-control"
                  rows="10"
                  required
                  onChange={(e) =>
                    setFormData({ ...form_data, message: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="my-3">
                <p className={`${message.type} text-center mb-0`}>
                  {message.info}
                </p>
              </div>
              <div className="text-center">
                <button type="submit">
                  {t("home.contact.message.button")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
