import React from "react";
import TopContacts from "../components/TopContacts/TopContacts";
import Header from "../components/Header/Header";
import Detail from "../components/Detail";
import Footer from "../components/Footer/Footer";
import UpButton from "../components/sections/services/UpButton/UpButton";

const Help = () => {
  const properties = {
    title: "Help",
    content: "This is the Helping page for VicTech Electronics",
  };

  return (
    <div>
      <TopContacts />
      <Header />
      <Detail property={properties} />
      <Footer />
      <UpButton />
    </div>
  );
};

export default Help;
