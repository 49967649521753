import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./DownloadInvoice.css";


const DownloadInvoice = (props) => {
  const [t] = useTranslation("global");
  const [pendingIcon, setPendingIcon] = useState("");
  const [downloadHovered, setDownloadHovered] = useState("");
  const [projectOrders, setProjectOrders] = useState([]);


  // Loading hours glass animation effect
  useEffect(() => {
    setProjectOrders(props.project_list)
    const interval_ID = setInterval(() => {
      setPendingIcon((prev_icon) => {
        if (prev_icon === "") return "-top";
        if (prev_icon === "-top") return "-split";
        else if (prev_icon === "-split") return "-bottom";
        else if (prev_icon === "-bottom") return "";
        // Add a default return value in case none of the conditions are met
        return prev_icon;
      });
    }, 1000);
    return () => clearInterval(interval_ID);
  }, [props.project_list]);

  return (
    props.trigger && (
      <>
        <div className="download-invoice d-flex flex-column">
          <div className="header">
            {t("profile.right_view.invoice.heading")}
          </div>

          {projectOrders.length > 0 ? (
            <div className="body d-flex flex-column">
              {projectOrders.map((order, index) => (
                <div
                  key={index}
                  className={`item d-flex flex-row justify-content-between align-items-center ${
                    index % 2 === 0 ? "even" : "odd"
                  }`}
                >
                  <span className="invoice-number">{order.invoice_number}</span>

                  <div className="download">
                    {order.invoice_download_link !== "" ? (
                      <Link
                        to={order.invoice_download_link}
                        className="download-icon bi bi-file-earmark-arrow-down me-4"
                        onMouseEnter={() =>
                          setDownloadHovered(order.invoice_number)
                        }
                        onMouseLeave={() => setDownloadHovered("")}
                      />
                    ) : (
                      <i
                        className={`download-icon bi bi-hourglass${pendingIcon} me-4`}
                        onMouseEnter={() =>
                          setDownloadHovered(order.invoice_number + "wait")
                        }
                        onMouseLeave={() => setDownloadHovered("")}
                      />
                    )}
                    <small
                      className={`download-hint ${
                        downloadHovered !== order.invoice_number && "d-none"
                      }`}
                    >
                      {t("profile.right_view.invoice.download")}
                    </small>
                    <small
                      className={`download-hint ${
                        downloadHovered !== order.invoice_number + "wait" &&
                        "d-none"
                      }`}
                    >
                      {t("profile.right_view.invoice.wait")}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-secondary ms-3">
              {t("profile.right_view.invoice.empty")}
            </div>
          )}
        </div>
      </>
    )
  );
};

export default DownloadInvoice;
