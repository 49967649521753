import React, { useEffect, useState } from "react";
import "./LeftViewContainer.css";
import Bills from "../Bills/Bills";
import Cart from "../Cart/Cart";
import Project from "../Project/Project";

const LeftViewContainer = (props) => {
  const [modifiedCartList, setModifiedCartList] = useState([])

  useEffect(() => {
    props.modifyCartList(modifiedCartList)
  },[props, modifiedCartList])

  return (
    <div className="left-view">
      <Project trigger={props.selected === "project" ? true : false} project_list={props.project_list}/>
      <Cart
        trigger={props.selected === "market" ? true : false}
        sidebar={props.sidebar}
        cart_list={props.cart_list}
        project_list={props.project_list}
        modifyCartList={setModifiedCartList}
      />
      <Bills trigger={props.selected === "bill" ? true : false} project_list={props.project_list}/>
    </div>
  );
};

export default LeftViewContainer;
