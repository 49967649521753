import React, { useState } from "react";
import "./InputField.css";

const InputField = (props) => {
  const [hide, setHide] = useState(true);

  return (
    <div className={`input-field d-flex flex-column ${props.margin}`}>
      <small className="label">{props.label}</small>
      <div className="input d-flex flex-row align-items-center">
        <div className="field-container d-flex flex-row justify-content-start align-content-center">
          <input
            className={`field ${props.isPassword ? "password" : "normal"} ${
              props.type === "textarea" ? "d-none" : props.type
            }`}
            value={props.value}
            checked={props.value}
            type={props.type || (hide && "password")}
            placeholder={props.instruction}
            required={props.required}
            pattern={props.pattern}
            onChange={(e) =>
              props.setValue(
                props.title,
                props.type === "checkbox" ? e.target.checked : e.target.value
              )
            }
          />
          <div className="after">{props.after}</div>
        </div>

        <textarea
          className={`field textarea ${props.type !== "textarea" && "d-none"}`}
          placeholder={props.instruction}
          required={props.textarea_required}
          onChange={(e) => props.setValue(props.title, e.target.value)}
        />
        <i
          className={`bi px-2 ${hide ? "bi-eye-fill" : "bi-eye-slash-fill"} ${
            !props.isPassword && "d-none"
          }`}
          onClick={() => setHide(!hide)}
        />
      </div>
      <small className="warning">{props.warning}</small>
    </div>
  );
};

export default InputField;
