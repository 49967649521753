import Cookies from "js-cookie";
let payment_data = {};

export const order_submition_amount = 3000;

export const paymentDetails = () => {
  let user_data = JSON.parse(
    Cookies.get("user_data") || sessionStorage.getItem("user_data")
  );

  payment_data["customer_firstname"] = user_data.user.first_name;
  payment_data["customer_lastname"] = user_data.user.last_name;
  payment_data["customer_address"] = user_data.profile.address;
  payment_data["customer_phone"] = user_data.profile.phone;
  payment_data["customer_email"] = user_data.user.email;
};

export const setPaymentDetails = (amount, description) => {
  payment_data["payment_amount"] = amount;
  payment_data["payment_description"] = description;
  return payment_data;
};
