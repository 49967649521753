import React from "react";
import Header from "../components/Header/Header";
import Detail from "../components/Detail";
import Footer from "../components/Footer/Footer";
import TopContacts from "../components/TopContacts/TopContacts";
import UpButton from "../components/sections/services/UpButton/UpButton";

const Terms_Condition = () => {
  const properties = {
    title: "Terms & Conditions",
    content:
      "This is the Terms and Condition page for VicTech Electronics site",
  };

  return (
    <>
      <TopContacts />
      <Header />
      <Detail property={properties} />
      <Footer />
      <UpButton />
    </>
  );
};

export default Terms_Condition;
