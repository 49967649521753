import React from "react";

import "./DisplayResources.css";
import data from "../../../../assets/data";

const DisplayResources = (props) => {
  const addOrder = async () => {
    localStorage.setItem("order_id", props.id);
    localStorage.setItem("order_name", props.name);
    localStorage.setItem("order_model", props.model);
    localStorage.setItem("order_price", props.price);
    props.addOrderHandler();
  };

  const scrollToTop = () => {
    props.selected();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (props.market) {
    return (
      <>
        <div className="col-lg-2 col-md-3 col-6 market-item">
          <div className="market-wrap">
            <img
              src={
                data.backend_url +
                "/media-files/image/resource/" +
                props.image +
                "-0"
              }
              loading="lazy"
              className="img-fluid"
              alt="Product Pic."
            />
            <div className="market-info" onClick={scrollToTop}>
              <h4>{props.name}</h4>
              <small className="victech-blue">{props.model}</small>
              <p>
                {new Intl.NumberFormat("en-Tz", {
                  style: "currency",
                  currency: "TZS",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(props.price)}
              </p>
            </div>
            <div className="market-link" onClick={addOrder}>
              Add to cart
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DisplayResources;
