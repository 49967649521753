import React, { useState } from "react";
import "./Registration.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

import InputField from "../../user_profile/components/InputField/InputField";
import Button from "../../user_profile/components/Button/Button";
import data from "../../../../assets/data";

const ImportantsDetails = (props) => {
  const [t] = useTranslation("global");
  const [message, setMessage] = useState({
    type: "",
    info: "",
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    address: "",
    phone: "",
    password: "",
    confirm_password: "",
    agree_accept: false,
  });

  const fieldUpdateHandler = (key, value) => {
    if (key === "first_name") setFormData({ ...formData, first_name: value });
    else if (key === "last_name")
      setFormData({ ...formData, last_name: value });
    else if (key === "username") setFormData({ ...formData, username: value });
    else if (key === "email") setFormData({ ...formData, email: value });
    else if (key === "address") setFormData({ ...formData, address: value });
    else if (key === "phone") setFormData({ ...formData, phone: value });
    else if (key === "password") setFormData({ ...formData, password: value });
    else if (key === "confirm_password")
      setFormData({ ...formData, confirm_password: value });
    else if (key === "agree_accept")
      setFormData({ ...formData, agree_accept: value });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    // Stop funtion excecution if there are data not filled
    for (let key of Object.keys(formData)) {
      if (formData[key] === "") {
        setMessage({
          type: "",
          info: "",
        });
        return;
      }
    }

    if (formData.agree_accept)
      if (formData.password === formData.confirm_password) {
        try {
          let response = await axios.post(
            data.backend_url + "/profile/registration/",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          let response_data = JSON.stringify(response.data);

          if (response.status === 201) {
            props.setTrigger(false);
            props.setLoginTrigger(true);
          } else {
            let errorMessage = t("form.registration.message1");
            if (response_data && response_data.payload) {
              errorMessage += " " + response_data.payload;
            }
            setMessage({
              info: errorMessage,
              type: "error",
            });
          }
        } catch (error) {
          setMessage({
            type: "error",
            info: t("form.registration.message2"),
          });
        }
      } else
        setMessage({
          type: "error",
          info: t("form.registration.message3"),
        });
    else
      setMessage({
        type: "error",
        info: t("form.registration.message4"),
      });
  };
  return (
    props.trigger && (
      <div className="important-details">
        <div className="popup-screen d-flex flex-column justify-content-center align-items-center">
          <div className="view-screen">
            <div className="x-close-icon my-0 py-0">
              <i className="bi bi-x" onClick={() => props.setTrigger(false)} />
            </div>
            <div className="pb-2">
              <h5 className="card-title text-center pb-0 fs-4">
                {t("form.registration.heading1")} &nbsp;
                <b className="victech-blue">{t("form.registration.account")}</b>
              </h5>
              <p className="text-center small">
                {t("form.registration.heading2")} &nbsp;
                <span className="victech-gold">
                  {t("form.registration.workshop")}
                </span>
              </p>
            </div>

            <form onSubmit={handleRegistration} className="form">
              <InputField
                label={"First name"}
                instruction={"Enter your first name"}
                required={true}
                type={"text"}
                title={"first_name"}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Last name"}
                instruction={"Enter your last name"}
                required={true}
                type={"text"}
                title={"last_name"}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Username"}
                instruction={"Enter your prefered username"}
                required={true}
                type={"text"}
                title={"username"}
                pattern="[A-Za-z0-9@./+/-/_]{1,150}"
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Address"}
                instruction={"Enter your location address"}
                required={true}
                type={"text"}
                title={"address"}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Email"}
                instruction={"Enter your email address"}
                required={true}
                type={"text"}
                title={"email"}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Phone number"}
                instruction={"Enter your phone"}
                required={true}
                type={"text"}
                title={"phone"}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Password"}
                instruction={"Enter your prefered password"}
                required={true}
                title={"password"}
                isPassword={true}
                setValue={fieldUpdateHandler}
              />
              <InputField
                label={"Confirm password"}
                instruction={"Enter your password again"}
                required={true}
                isPassword={true}
                title={"confirm_password"}
                setValue={fieldUpdateHandler}
                warning={
                  formData.confirm_password &&
                  formData.password !== formData.confirm_password &&
                  t("profile.right_view.password.missmatch")
                }
              />

              <InputField
                required={true}
                type={"checkbox"}
                title={"agree_accept"}
                setValue={fieldUpdateHandler}
                after={`${t("form.registration.agree")}`}
              />

              <p className={`${message.type} text-center m-0 mt-3`}>
                {message.info}
              </p>

              <label
                htmlFor="submit-btn"
                className="submit-btn d-flex justify-content-center"
              >
                <Button
                  button="Register"
                  color="blue"
                  size="half"
                  type="submit"
                  position="center"
                  clicked={() => {}}
                />
              </label>
              <input id="submit-btn" type="submit" className="d-none" />
            </form>

            <div className="text-center mb-5">
              <p className="small mb-0">
                {t("form.registration.info")} &nbsp;
                <span
                  className="link victech-blue"
                  onClick={() => {
                    props.setTrigger(false);
                    props.setLoginTrigger(true);
                  }}
                >
                  {t("form.registration.link2")}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ImportantsDetails;
