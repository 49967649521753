import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
// ------------------------------------ //
import "./MarketSection.css";
import Alert from "../../services/Alert/Alert";
import data from "../../../../assets/data";
import Payment from "../../services/Payment";
import Loading from "../../services/Loading/Loading";
import DisplayResources from "../DisplayResources/DisplayResources";
import Login from "../../services/Login/Login";
import Registration from "../../services/Registration/Registration";
import { paymentDetails, setPaymentDetails } from "../../../../assets/payment";
import { sections, market_variables } from "../marketValueData";
import ViewResource from "../ViewResource/ViewResource";

const MarketSection = () => {
  const [t] = useTranslation("global");
  const [resources, setResources] = useState([]);
  const [active, setActive] = useState("all");
  const [filter, setFilter] = useState([]);
  const [order, setOrder] = useState({});
  const [feedbackTrigger, setFeedbackTrigger] = useState(false);
  const [confirmTrigger, setConfirmTrigger] = useState(false);
  const [paymentTrigger, setPaymentTrigger] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [detailTrigger, setDetailTrigger] = useState(false);
  const [resouceTriggeredDetail, setResourceTriggeredDetail] = useState("");
  const [view, setView] = useState(0);
  const [loginTrigger, setLoginTrigger] = useState(false);
  const [registrationTrigger, setRegistrationTrigger] = useState(false);
  const [loginReqAlertTrigger, setLoginReqAlertTrigger] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [smallSearch, setSmallSearch] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  const username =
    Cookies.get("user") || sessionStorage.getItem("user") || null;
  const [orderResponse, setOrderResponse] = useState({
    message: "",
    type: "success",
  });

  useEffect(() => {
    axios
      .get(data.backend_url + "/market/resource/")
      .then((response) => {
        setResources(response.data);
        setFilter(response.data);
        setSelectedResource(response.data[0]);
      })
      .catch((error) => {
        console.error("Error while featching resource data", error);
      });
  }, []);

  const searchHandler = (searched_data) => {
    searched_data = searched_data.toLowerCase();
    const filtered_data = resources.filter(
      (item) =>
        item.fields.name.toLowerCase().includes(searched_data) ||
        item.fields.model.toLowerCase().includes(searched_data)
    );

    setActive("all");
    if (filtered_data) setFilter(filtered_data);
  };

  const handleFilter = async (category) => {
    const filtered_data = resources.filter(
      (item) => item.fields.category === category
    );

    setActive(category);
    if (category === "all") setFilter(resources);
    else setFilter(filtered_data);
  };

  const setOrderQuantityHandler = (quantity) => {
    if (quantity) market_variables.order_quantity = parseInt(quantity);
    else market_variables.order_quantity = 1;
  };

  const addToCartHandler = async () => {
    market_variables.cart_order = {
      username: username,
      item: {
        name: order.name,
        model: order.model,
        detail: order.detail,
        quantity: market_variables.order_quantity,
        price: order.price * market_variables.order_quantity,
      },
    };

    axios.post(
      data.backend_url + "/profile/cart/add/",
      market_variables.cart_order,
      {
        header: { "Content-Type": "application/json" },
      }
    );

    axios
      .get(data.backend_url + "/profile/cart/" + username)
      .then((response) => {
        const jsonString = response.data.replace(/'/g, '"');
        if (response.status === 202) {
          const cart_list = JSON.parse(jsonString);
          Cookies.set("cart_list", cart_list.length);
          sessionStorage.setItem("cart_list", cart_list.length);
        }
      });
  };

  const orderSubmitionHandler = async () => {
    paymentDetails();
    setLoading(true);
    setConfirmTrigger(false);

    const paymentData = setPaymentDetails(
      order.price * market_variables.order_quantity,
      {
        header: "market",
        data: {
          username: market_variables.cart_order.username,
          order_list: [market_variables.cart_order.item],
          order_price: market_variables.cart_order.item.price,
        },
      }
    );
    axios
      .post(
        // data.backend_url + "/market/resource/order/",
        data.backend_url + "/victech/payment/",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setPaymentURL(response.data.url);
          setPaymentTrigger(true);
        } else {
          setOrderResponse({ message: response.data.message, type: "error" });
        }
      })
      .catch((error) => {
        console.error("Fail to place order", error);
      });
  };
  const viewResource = (
    <>
      {selectedResource ? (
        <ViewResource fields={selectedResource.fields} />
      ) : (
        <></>
      )}
    </>
  );
  const displayResources = (
    <>
      {filter.slice(view * 30, view * 30 + 30).map((resource) => (
        <DisplayResources
          key={resource.pk}
          image={resource.fields.sku}
          id={resource.pk}
          name={resource.fields.name}
          model={resource.fields.model}
          price={resource.fields.price}
          details={resource.fields.detail}
          market={resource.fields.is_sold}
          setDetailTrigger={() => {
            setResourceTriggeredDetail(resource.fields.detail);
            setDetailTrigger(true);
          }}
          addOrderHandler={() => {
            if (username) {
              setOrder({
                name: resource.fields.name,
                model: resource.fields.model,
                detail: resource.fields.detail,
                price: resource.fields.price,
              });
              setFeedbackTrigger(true);
            } else setLoginReqAlertTrigger(true);
          }}
          selected={() => setSelectedResource(resource)}
        />
      ))}
    </>
  );

  return (
    <>
      <Alert
        trigger={detailTrigger}
        setTrigger={setDetailTrigger}
        heading={"Product details:"}
        message={resouceTriggeredDetail}
      />
      <Alert
        trigger={loginReqAlertTrigger}
        setTrigger={setLoginReqAlertTrigger}
        type="choice"
        heading={t("alert.login_required.heading")}
        message={t("alert.login_required.statement")}
        accept_btn_text={t("alert.login_required.login_btn")}
        cancel_btn_text={t("alert.login_required.reg_btn")}
        onCancel={() => {
          setLoginReqAlertTrigger(false);
          setRegistrationTrigger(true);
        }}
        onAccept={() => {
          setLoginReqAlertTrigger(false);
          setLoginTrigger(true);
        }}
      />

      <Login
        trigger={loginTrigger}
        setTrigger={setLoginTrigger}
        setRegistrationTrigger={setRegistrationTrigger}
      />
      <Registration
        trigger={registrationTrigger}
        setTrigger={setRegistrationTrigger}
        setLoginTrigger={setLoginTrigger}
      />

      <Alert
        trigger={feedbackTrigger}
        getFeedback={setOrderQuantityHandler}
        setTrigger={setFeedbackTrigger}
        heading={t("market.popup.feedback.heading")}
        message={t("market.popup.feedback.instruction")}
        type="feedback"
        feedback_placeholder={t("market.popup.feedback.placeholder")}
        right_btn_text={t("market.popup.feedback.right_btn")}
        left_btn_text={t("market.popup.feedback.left_btn")}
        feedback_input_type="number"
        feedback_min_val={1}
        onRightSelection={() => {
          setFeedbackTrigger(false);
          setConfirmTrigger(true);
        }}
        onLeftSelection={() => {
          setFeedbackTrigger(false);
          addToCartHandler();
        }}
      />
      <Alert
        trigger={confirmTrigger}
        setTrigger={setConfirmTrigger}
        heading={t("market.popup.confirmation.heading")}
        message={`
          ${t("market.popup.confirmation.instruction")}
          ${order.name} - ${order.model}
          ${t("market.popup.confirmation.quantity")} = ${
          market_variables.order_quantity
        }
          ${t("market.popup.confirmation.cost")} = ${new Intl.NumberFormat(
          "en-Tz",
          {
            style: "currency",
            currency: "TZS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        ).format(order.price * market_variables.order_quantity)}
        `}
        type="choice"
        accept_btn_text={t("market.popup.confirmation.confirm_btn")}
        cancel_btn_text={t("market.popup.confirmation.cancel_btn")}
        onClose={() => setConfirmTrigger(false)}
        onCancel={() => setConfirmTrigger(false)}
        onAccept={() => orderSubmitionHandler()}
        response={orderResponse.message}
        response_type={orderResponse.type}
      />
      <Loading trigger={loading} />
      <Payment
        setLoading={setLoading}
        trigger={paymentTrigger}
        setTrigger={setPaymentTrigger}
        url={paymentURL}
      />
      <section id="market" className="market pt-2">
        <div className="container">
          <div className="top-section d-flex flex-row justify-content-between align-items-start">
            <div className="section-title" data-aos="fade-up">
              <h2>
                <i className="bi bi-cart3"></i>&nbsp;
                {t("market.heading")}
              </h2>
            </div>

            <div className="section-content d-flex flex-row">
              <div className="market-menu">
                <div
                  className="selected d-flex flex-row"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <i
                    class={`bi bi-caret-${
                      dropdown ? "down" : "right"
                    }-fill pe-1`}
                  />
                  {t(`market.selection.${active}`)}
                </div>

                <div
                  className={`dropdown ${
                    dropdown ? "d-flex" : "d-none"
                  } justify-content-center`}
                >
                  <ul
                    className="dropdown-menu-list d-flex flex-column align-items-start"
                    data-aos="fade-down"
                    data-aos-delay="100"
                  >
                    {sections.map((section) => (
                      <li
                        key={section}
                        className={
                          active === section ? "filter-active" : undefined
                        }
                        onClick={() => handleFilter(section)}
                      >
                        {t(`market.selection.${section}`)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="search">
                <div className="d-none d-md-flex flex-row align-items-center">
                  <input
                    className="market-search-input text-center"
                    type="text"
                    placeholder={t("market.search.placeholder")}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button
                    className="btn btn-sm market-search-btn"
                    onClick={() => searchHandler(searchValue)}
                  >
                    {t("market.search.button")}
                  </button>
                </div>

                <div>
                  <div
                    className={`small-screen-search ${
                      smallSearch ? "d-flex" : "d-none"
                    } flex-row justify-content-between`}
                  >
                    <input
                      className="input text-center"
                      type="text"
                      placeholder={t("market.search.placeholder")}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button
                      className="btn btn-sm"
                      onClick={() => {
                        setSmallSearch(false);
                        searchHandler(searchValue);
                      }}
                    >
                      {t("market.search.button")}
                    </button>
                    <i
                      className="close bi bi-x"
                      onClick={() => setSmallSearch(false)}
                    />
                  </div>
                  <i
                    className="search-icon d-flex d-md-none bi bi-search"
                    onClick={() => setSmallSearch(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-0" />
          <div
            className="row market-container"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            {viewResource}
            <hr />
            {displayResources}

            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn border border-5 m-2"
                onClick={() => {
                  let value = view - 1;
                  if (value < 0) value = 0;
                  setView(value);
                }}
              >
                {t("market.buttons.prev")}
              </button>
              <button
                className="btn border border-5 m-2"
                onClick={() => {
                  let value = view + 1;
                  let limit = filter.length / 30;
                  if (value > limit) value--;
                  setView(value);
                }}
              >
                {t("market.buttons.next")}
              </button>
            </div>
          </div>

          <p className="text-center lead mt-5 mb-0">
            {t("market.note")}&nbsp;
            <>
              <i className="victech-gold">Vic</i>
              <b className="victech-blue">Tech</b> <small>Electronics</small>
            </>
          </p>
        </div>
      </section>
      
    </>
  );
};

export default MarketSection;
