import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import "./Bills.css";
import {
  paymentDetails,
  setPaymentDetails,
} from "../../../../../../assets/payment";
import Button from "../../../components/Button/Button";
import Payment from "../../../../services/Payment";
import Loading from "../../../../services/Loading/Loading";
import Alert from "../../../../services/Alert/Alert";
import Fail from "../../../../services/Response/Fail";
import data from "../../../../../../assets/data";

const Bills = (props) => {
  const [paymentTrigger, setPaymentTrigger] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  // const [feedbackMessage, setFeedbackMessage] = useState("");
  const [selectTrigger, setSelectTrigger] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [orderResponse, setOrderResponse] = useState("");
  const [projectOrders, setProjectOrders] = useState([]);
  const [failTrigger, setFailTrigger] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [dueCalc, setDueCalc] = useState(99999);
  const [loading, setLoading] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [t] = useTranslation("global");

  useEffect(() => {
    setProjectOrders(props.project_list);

    // Loading due calculation animation effect
    const interval_ID = setInterval(() => {
      let generateRandom = (min = 0, max = 999999) => {
        let difference = max - min;
        let rand = Math.random();
        rand = Math.floor(rand * difference);
        rand = rand + min;
        return rand;
      };
      setDueCalc(generateRandom());
    }, 100);
    return () => clearInterval(interval_ID);
  }, [props.project_list]);

  const paymentHandler = async (invoice) => {
    // if (selectedAmount > minAmount) {
    setSelectTrigger(false);
    paymentDetails();
    setLoading(true);

    const paymentData = setPaymentDetails(selectedAmount, {
      header: "bill",
      data: invoice,
    });
    axios
      .post(data.backend_url + "/victech/payment/", paymentData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setPaymentURL(response.data.url);
          setPaymentTrigger(true);
        } else {
          setOrderResponse(
            response.data.message || t("profile.right_view.invoice.response")
          );
          setLoading(false);
          setFailTrigger(true);
        }
      })
      .catch((error) => {
        console.error("Fail to place order", error);
        setLoading(false);
        setFailTrigger(true);
      });
    // } else {
    //   setFeedbackMessage(`For now, you have to pay atlest ${minAmount}`);
    //   let intervalId = setInterval(() => {
    //     setFeedbackMessage("");
    //   }, 1000);
    //   setTimeout(() => {
    //     clearInterval(intervalId);
    //   }, 5000);
    // }
  };
  return (
    props.trigger && (
      <>
        <Alert
          trigger={selectTrigger}
          getFeedback={setSelectedAmount}
          setTrigger={setSelectTrigger}
          heading={t("profile.right_view.invoice.alert.heading")}
          message={t("profile.right_view.invoice.alert.message")}
          type="feedback"
          feedback_placeholder={t(
            "profile.right_view.invoice.alert.placeholder"
          )}
          right_btn_text={t("profile.right_view.invoice.alert.button")}
          left_btn_text={"Cancel"}
          feedback_input_type="number"
          feedback_min_val={minAmount}
          erroMessage={/*feedbackMessage*/ ""}
          onRightSelection={() => {
            paymentHandler(selectedInvoice);
          }}
          onLeftSelection={() => setSelectTrigger(false)}
        />
        <Loading trigger={loading} />
        <Payment
          setLoading={setLoading}
          trigger={paymentTrigger}
          setTrigger={setPaymentTrigger}
          url={paymentURL}
        />

        <Fail
          trigger={failTrigger}
          setTrigger={setFailTrigger}
          message={orderResponse}
        />
        <div className="bills d-flex flex-column justify-content-center">
          <div className="header d-flex flex-column">
            <h3 className="title">Bills</h3>
            <span className="detail">Make payments</span>
          </div>

          {projectOrders.length > 0 ? (
            <div className="body d-flex flex-column">
              {projectOrders.map((order, index) => (
                <div
                  key={order.id}
                  className={`item d-flex flex-column justify-content-between align-items-start px-md-5 ${
                    index % 2 === 0 ? "even" : "odd"
                  }`}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center my-2  w-100">
                    <strong className="h5 fw-bold">{order.title}</strong>
                    <strong className="invoice-number">
                      {order.invoice_number}
                    </strong>
                  </div>

                  {order.due > 0.0 ? (
                    <div className="cost-list d-flex flex-column ms-3 ms-md-5 w-75 w-md-50">
                      <div className="d-flex flex-row justify-content-between">
                        <span className="material">Material cost</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.material_cost)}
                          /=
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="service">Service cost</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.service_cost)}
                          /=
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="emergence">Emergence cost</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.emergence_cost)}
                          /=
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="total">Total cost</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.total_cost)}
                          /=
                        </span>
                      </div>
                      {order.discount > 0 && (
                        <div className="d-flex flex-row justify-content-between">
                          <span className="discount">Discount</span>
                          <span className="cost ms-3">
                            {new Intl.NumberFormat("en-Tz", {
                              style: "currency",
                              currency: "TZS",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(order.discount)}
                            /=
                          </span>
                        </div>
                      )}
                      <div className="d-flex flex-row justify-content-between">
                        <span className="paid">Paid amount</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.paid_amount)}
                          /=
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="due">Due</span>
                        <span className="cost ms-3">
                          {new Intl.NumberFormat("en-Tz", {
                            style: "currency",
                            currency: "TZS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(order.due)}
                          /=
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-row justify-content-between ms-3 ms-md-5 w-md-50">
                      <span className="due-cost">Cost calculated</span>
                      <span className="cost ms-3">
                        {new Intl.NumberFormat("en-Tz", {
                          style: "currency",
                          currency: "TZS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(dueCalc)}
                        /=
                      </span>
                    </div>
                  )}

                  <div className={`pay_button w-100 d-md-none`}>
                    <Button
                      button={t("profile.right_view.invoice.button")}
                      color={"blue"}
                      disabled={order.due === undefined || order.due <= 0}
                      size="half"
                      clicked={() => {
                        setSelectedInvoice(order.invoice_number);
                        setMinAmount(
                          order.paid_amount <= 0
                            ? order.material_cost +
                                order.emergence_cost +
                                order.service_cost * 0.3
                            : 100
                        );
                        setSelectTrigger(true);
                      }}
                    />
                  </div>

                  <div className="pay_button d-none d-md-block align-self-end">
                    <Button
                      button={t("profile.right_view.invoice.button")}
                      color="blue"
                      disabled={order.due === undefined || order.due <= 0}
                      clicked={() => {
                        setSelectedInvoice(order.invoice_number);
                        setMinAmount(
                          order.paid_amount <= 0
                            ? order.material_cost +
                                order.emergence_cost +
                                order.service_cost * 0.3
                            : 100
                        );
                        setSelectTrigger(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-secondary ms-3">
              {t("profile.right_view.invoice.empty")}
            </div>
          )}
        </div>
      </>
    )
  );
};

export default Bills;
