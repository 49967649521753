import React from "react";
import TopContacts from "../components/TopContacts/TopContacts";
import Header from "../components/Header/Header";
import Detail from "../components/Detail";
import Footer from "../components/Footer/Footer";
import UpButton from "../components/sections/services/UpButton/UpButton";

const PrivacyPolicy = () => {
  const properties = {
    title: "Privacy & Police",
    content: "This is the Privacy and Policy page for VicTech Electronics site",
  };

  return (
    <>
      <TopContacts />
      <Header />
      <Detail property={properties} />
      <Footer />
      <UpButton />
    </>
  );
};

export default PrivacyPolicy;
