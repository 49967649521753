import React from "react";
import axios from "axios";

import data from "../../../../../../assets/data";
import Button from "../../../components/Button/Button";

const Pending = (props) => {
  const btnClickHandler = (response) => {
    props.getResponse(props.project_index, response);
    axios
      .get(
        data.backend_url +
          "/project/order/response/" +
          props.invoice_number +
          "/" +
          response
      )
      .catch((error) => console.error("Response request failed"));
  };

  return (
    props.trigger && (
      <div>
        <div
          className={`overview d-flex flex-row align-items-top justify-content-between px-3 ${
            props.project_index % 2 === 0 ? "even" : "odd"
          }`}
        >
          <span className="py-2 pe-2">{props.project_title}</span>
          <div className="right-aligned d-flex flex-row align-items-top">
            <strong className="inv-no py-2 px-2">{props.invoice_number}</strong>
            <div
              className={`buttons d-flex flex-column flex-sm-row justify-content-center align-items-center ps-2 py-2 ${
                props.section !== "pending" && "d-none"
              }`}
            >
              <Button
                button="Accept"
                color="blue"
                size="btn-sm"
                btn_value="accepted"
                disabled={props.invoice_download_link === "" ? true : false}
                clicked={btnClickHandler}
              />
              <Button
                button="Reject"
                color="gold"
                size="btn-sm"
                btn_value="rejected"
                clicked={btnClickHandler}
                disabled={props.invoice_download_link === "" ? true : false}
              />
              <Button
                button="Delete"
                color="danger"
                size="btn-sm"
                btn_value="deleted"
                clicked={btnClickHandler}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Pending;
