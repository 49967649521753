import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_en from "./assets/Language/en/global.json";
import global_sw from "./assets/Language/sw/global.json";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { global: global_en },
      sw: { global: global_sw },
    },
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: {
      order: ["cookie", "localStorage", "htmlTag"],
      caches: ["cookie", "localStorage"],
    },
  });
