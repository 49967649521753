import Cookies from "js-cookie";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderForm = (props) => {
  const [t] = useTranslation("global");
  const [appearanceDisplay, setAppearanceDisplay] = useState("none");
  const [message, setMessage] = useState({
    type: "",
    info: "",
  });
  const [form_data, setFormData] = useState({
    username: Cookies.get("user") || sessionStorage.getItem("user"),
    title: "",
    description: "",
    document_info: null,
    category: "unknown",
    appearance: "simple",
    simulation: false,
    circuit_design_analysis: false,
    pcb_design_etching: false,
    pcb_assembling: false,
    firmware_programming: false,
    enclosure_3D_design: false,
    printing_enclosure: false,
    software_system: false,
  });

  const categories = [
    { title: t("form.order.category.unknown"), value: 'unknown' },
    { title: t("form.order.category.prototype"), value: "prototype" },
    { title: t("form.order.category.product"), value: "product" },
  ];

  const appearances = [
    { title: t("form.order.appearance.simple"), value: "simple" },
    { title: t("form.order.appearance.normal"), value: "normal" },
    { title: t("form.order.appearance.good"), value: "good" },
    { title: t("form.order.appearance.best"), value: "best" },
  ];

  const handleSubmit = async (e) => {
    console.log(`Form data: ${JSON.stringify(form_data)}`)
    e.preventDefault();
    if (form_data.category !== "unknown") {
      props.setTrigger(false);
      props.formData(form_data);
      props.confirmSubmition(true);
    } else {
      setMessage({
        info: t("form.order.message2"),
        type: "error",
      });
    }
  };

  const documentChangeHandler = async (e) => {
    let document = e.target.files[0];
    setFormData({
      ...form_data,
      document_info: document,
    });
  };

  return props.trigger ? (
    <>
      <div className="popup">
        <div className="section d-block d-min-flex align-items-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="x-close-icon my-0 py-0">
                    <i
                      className="bi bi-x"
                      onClick={() => props.setTrigger(false)}
                    />
                  </div>
                  <div className="pb-2">
                    <h3 className="card-title text-center pb-0 fs-4">
                      <Link to="/">
                        <span className="victech-gold">Vic</span>
                        <b className="victech-blue">Tech</b>{" "}
                        <small className="text-dark ms-1">Electronics</small>
                      </Link>
                    </h3>
                    <p className="h4 text-center">{t("form.order.heading")}</p>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="form row g-3 needs-validation mb-3 mx-2 justify-content-center"
                  >
                    <div className="col-12">
                      <p className="form-label">{t("form.order.title")}</p>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          value={form_data.title}
                          className="form-control"
                          required
                          onChange={(e) =>
                            setFormData({ ...form_data, title: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <p className="form-label">
                        {t("form.order.description")}
                      </p>
                      <textarea
                        value={form_data.description}
                        className="form-control"
                        cols="30"
                        rows="5"
                        required
                        placeholder={t("form.order.sample_description")}
                        onChange={(e) =>
                          setFormData({
                            ...form_data,
                            description: e.target.value,
                          })
                        }
                      />
                      <div className="d-flex flex-row justify-content-end mt-2">
                        <label
                          htmlFor="file"
                          className="project-order-file-label d-flex flex-row align-items-center"
                        >
                          <i className="bi bi-file-earmark-arrow-up me-2" />
                          <span>{t("form.order.document")}</span>
                          <small className="text-secondary ms-1">
                            ({t("form.order.optional")})
                          </small>
                        </label>
                        <input
                          id="file"
                          className="d-none"
                          type="file"
                          accept=".pdf,.xlsx,.xls,.doc,.docx"
                          onChange={documentChangeHandler}
                        />
                        &nbsp;
                      </div>
                    </div>

                    {/* Category selections */}
                    <div className="col-12 row mt-4">
                      <div className="col">
                        <p className="form-label">
                          {t("form.order.category.heading")}
                        </p>
                        <select
                          value={form_data.category}
                          className="form-control"
                          onChange={(e) => {
                            if (e.target.value === "prototype")
                              setAppearanceDisplay("block");
                            else setAppearanceDisplay("none");
                            setFormData({
                              ...form_data,
                              category: e.target.value,
                            });
                          }}
                        >
                          {categories.map((category) => (
                            <option key={category.value} value={category.value}>
                              {category.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="col-md-6 col-sm-12"
                        style={{ display: appearanceDisplay }}
                      >
                        <p className="form-label">
                          {t("form.order.appearance.heading")}
                        </p>
                        <select
                          value={form_data.quality}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              quality: e.target.value,
                            })
                          }
                        >
                          {appearances.map((appearance) => (
                            <option
                              key={appearance.value}
                              value={appearance.value}
                            >
                              {appearance.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="victech-blue col-12 mt-5">
                      <strong className="h5">
                        {t("form.order.service.heading")}:
                      </strong>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.simulation}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              simulation: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.first")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.circuit_design_analysis}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              circuit_design_analysis: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.second")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.pcb_design_etching}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              pcb_design_etching: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.third")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.pcb_assembling}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              pcb_assembling: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.fourth")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.firmware_programming}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              firmware_programming: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.fifth")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.enclosure_3D_design}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              enclosure_3D_design: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.sixth")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.printing_enclosure}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              printing_enclosure: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.seventh")}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-check">
                        <input
                          value={form_data.software_system}
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            setFormData({
                              ...form_data,
                              software_system: e.target.checked,
                            })
                          }
                        />
                        <p className="form-check-label">
                          {t("form.order.service.eighth")}
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <p className={`${message.type} text-center mb-0`}>
                        {message.info}
                      </p>
                    </div>

                    <div className="col-lg-3 col-md-8 mt-5">
                      <button
                        className="btn btn-lg text-light w-100"
                        type="submit"
                      >
                        {t("form.order.button")}
                      </button>
                    </div>
                  </form>

                  <div className="pt-4 pb-2">
                    <p className="small mb-0 text-center">
                      {t("form.order.info")} &nbsp;
                      <strong className="victech-blue">
                        {t("form.order.workshop")}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ""
  );
};

export default OrderForm;
