import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const user = Cookies.get("user") || sessionStorage.getItem("user");

  if (!user) {
    return (
      <>
        alert('Your are needed to be register, Please Join Us')
        <Navigate to={"/"} />
      </>
    );
  }

  return children;
};

export default RequireAuth;
