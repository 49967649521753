import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Registration from "./sections/services/Registration/Registration";
import Login from "./sections/services/Login/Login";
import Cookies from "js-cookie";
import Alert from "./sections/services/Alert/Alert";
import { useNavigate } from "react-router-dom";

const Showcase = () => {
  const [t] = useTranslation("global");

  const [logTrigger, setLogTrigger] = useState(false);
  const [regTrigger, setRegTrigger] = useState(false);
  const [alertTrigger, setAlertTrigger] = useState(false);
  let navigate = useNavigate();
  return (
    <>
      <Alert
        trigger={alertTrigger}
        setTrigger={setAlertTrigger}
        type={"choice"}
        heading={t("alert.account_pressent.heading")}
        message={t("alert.account_pressent.statement")}
        accept_btn_text="Logout"
        cancel_btn_text="Cancel"
        onAccept={() => {
          setAlertTrigger(false);
          Cookies.remove("user");
          Cookies.remove("user_data");
          Cookies.remove("cart_list");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("user_data");
          sessionStorage.removeItem("cart_list");
          navigate("/");
        }}
        onCancel={() => setAlertTrigger(false)}
      />
      <Login
        trigger={logTrigger}
        setTrigger={setLogTrigger}
        setRegistrationTrigger={setRegTrigger}
      />
      <Registration
        trigger={regTrigger}
        setTrigger={setRegTrigger}
        setLoginTrigger={setLogTrigger}
      />

      <div className="showcase p-5 text-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-8 text-md-start text-center">
              <h1>
                All About <span className="victech-gold">Electronics</span>
              </h1>
              <p className="lead">{t("home.showcase.heading")}</p>
              <span
                className="btn btn-lg text-light"
                onClick={() => {
                  Cookies.get("user") || sessionStorage.getItem("user")
                    ? setAlertTrigger(true)
                    : setRegTrigger(true);
                }}
              >
                {t("home.showcase.button")}
              </span>
            </div>
            <div className="col">
              <img
                loading="lazy"
                className="img-fluid showcase-image d-none d-md-block"
                src="./image/logo.png"
                alt="VicTech"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Showcase;
