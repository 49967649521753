import React from 'react'
import data from '../../../assets/data'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Chats = () => {
  const [t] = useTranslation('global')

  return (
    <section id="chat" className="chat my-5">
      <div className="container">
        <div className="row" data-aos="zoom-in">
          <div className="col-lg-9 text-center text-lg-start">
            <h3>{t("home.link.heading")}</h3>
            <p>
              {t("home.link.content")} <br />
              {t("home.link.addition")}
            </p>
          </div>
          <div className="col-lg-3 chat-btn-container text-center">
            <Link
              to={data.our_social.whatsapp}
              className="chat-btn align-middle"
            >
              <i className="h3 bi bi-whatsapp"></i> WhatsApp
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chats
