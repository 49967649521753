import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";

import "./EditProfile.css";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import data from "../../../../../../assets/data";

const EditProfile = (props) => {
  const [t] = useTranslation("global");
  const user =
    Cookies.get("user") || sessionStorage.getItem("user") || "VictoniX";
  const user_data = JSON.parse(
    Cookies.get("user_data") || sessionStorage.getItem("user_data")
  );

  const [message, setMessage] = useState({
    info: "",
    type: "",
  });

  const [formData, setFormData] = useState({
    username: user,
    new_username: user_data.user.username,
    profile_picture: null,
    first_name: user_data.user.first_name,
    last_name: user_data.user.last_name,
    address: user_data.profile.address,
    phone: user_data.profile.phone,
    email: user_data.user.email,
  });

  const InputFieldHandler = (key, value) => {
    if (key === "username") setFormData({ ...formData, new_username: value });
    else if (key === "first_name")
      setFormData({ ...formData, first_name: value });
    else if (key === "last_name")
      setFormData({ ...formData, last_name: value });
    else if (key === "address") setFormData({ ...formData, address: value });
    else if (key === "phone") setFormData({ ...formData, phone: value });
    else if (key === "email") setFormData({ ...formData, email: value });
  };

  const imageChangeHandler = (e) => {
    const file = e.target.files[0];
    const imgElement = document.getElementById("image-tag");

    if (file) {
      setFormData({ ...formData, profile_picture: file });

      if (imgElement) {
        const reader = new FileReader();
        reader.onload = function (e) {
          imgElement.src = e.target.result; // update image-tag src
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const submitHandler = async () => {
    try {
      let response = await axios.post(
        data.backend_url + "/profile/edit/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let response_data = JSON.stringify(response.data.payload);
      let username = response.data.payload.user.username;

      if (response.status === 202) {
        if (Cookies.get("user")) {
          Cookies.set("user", username);
          Cookies.set("user_data", response_data);
        } else {
          sessionStorage.setItem("user", username);
          sessionStorage.setItem("user_data", response_data);
        }

        setMessage({
          type: "success",
          info: "Profile Edited successully",
        });

      } else {
        setMessage({
          type: "error",
          info: response_data,
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        info: "Sorry! Fail due to server response error",
      });
    }
  };

  return (
    props.trigger && (
      <div className="edit-profile d-flex flex-column">
        <div className="header">{t("profile.right_view.edit.heading")}</div>

        <div className="form d-flex flex-column">
          <div className="user-image d-flex align-items-center justify-content-center m-3">
            <label htmlFor="image-file">
              <img
                id="image-tag"
                className="image"
                src={`${data.backend_url}/media-files/profile/${user}`}
                loading="lazy"
                alt="user"
              />
            </label>
            <input
              id="image-file"
              className="d-none"
              type="file"
              accept="image/*"
              onChange={imageChangeHandler}
            />
          </div>

          <InputField
            title={"username"}
            label={t("profile.right_view.edit.username")}
            type={"text"}
            instruction={formData.username}
            setValue={InputFieldHandler}
          />

          <InputField
            title={"first_name"}
            label={t("profile.right_view.edit.first_name")}
            type={"text"}
            instruction={formData.first_name}
            setValue={InputFieldHandler}
          />

          <InputField
            title={"last_name"}
            label={t("profile.right_view.edit.last_name")}
            type={"text"}
            instruction={formData.last_name}
            setValue={InputFieldHandler}
          />

          <InputField
            title={"address"}
            label={t("profile.right_view.edit.address")}
            type={"text"}
            instruction={formData.address}
            setValue={InputFieldHandler}
          />
          <InputField
            title={"phone"}
            label={t("profile.right_view.edit.phone")}
            type={"phone"}
            instruction={formData.phone}
            setValue={InputFieldHandler}
          />
          <InputField
            title={"email"}
            label={t("profile.right_view.edit.email")}
            type={"email"}
            instruction={formData.email}
            setValue={InputFieldHandler}
          />

          <p className={`${message.type} text-center m-0 mt-3`}>
            {message.info}
          </p>

          <div className="d-flex align-items-center justify-content-center">
            <Button
              button={t("profile.right_view.edit.button")}
              color={"blue"}
              width={"half"}
              clicked={submitHandler}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default EditProfile;
