import data from "../../../assets/data";
import axios from "axios";

const DisplayMedia = (props) => {
  let value = props.properties;

  const handleFullScreen = async (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  // const handleMouseEnter = async (element) => {
  //   // element;
  // };

  const handleDownload = async (filename, download_link) => {
    try {
      const response = await axios.get(download_link, { responseType: "blob" });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error download media", error);
    }
  };

  const displayImage = (
    <>
      <div className="media-wrap">
        <img
          src={data.backend_url + "/media-files/media/image/" + value.pk + "/0"}
          loading="lazy"
          className="img-fluid"
          alt="Media file"
        />
      </div>
      <div className="media-details-wrap">
        <div className="row mt-2 container">
          <div className="col-1">
            <p className="h5 bi bi-image" />
          </div>
          <div className="col-10">
            <h6 className="text-truncate my-0">{value.title}</h6>
            <small className="text-secondary text-truncate my-0">
              {value.description.substring(0, 25)}
            </small>
          </div>
          <div className="col-1">
            <p
              className="h5 bi bi-cloud-download"
              onClick={() =>
                handleDownload(
                  "ViTech_" + value.title + "_image.png",
                  data.backend_url +
                    "/media-files/media/image/" +
                    value.pk +
                    "/1"
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );

  const displayVideo = (
    <>
      <div className="media-wrap">
        <video
          width="100%"
          controls
          onClick={(e) => handleFullScreen(e.target)}
          // onMouseEnter={(e) => handleMouseEnter(e.target)}
        >
          <source
            src={
              data.backend_url + "/media-files/media/video/" + value.pk + "/0"
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="media-details-wrap">
        <div className="row container">
          <div className="col-1">
            <p className="h5 bi bi-camera-reels" />
          </div>
          <div className="col-10">
            <h6 className="text-truncate my-0">{value.title}</h6>
            <small className="text-secondary text-truncate my-0">
              {value.description.substring(0, 25)}
            </small>
          </div>
          <div className="col-1">
            <p
              className="h5 bi bi-cloud-download"
              onClick={() =>
                handleDownload(
                  "ViTech_" + value.title + "_video.mp4",
                  data.backend_url +
                    "/media-files/media/video/" +
                    value.pk +
                    "/1"
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="col-lg-4 col-md-6 media-item">
        {value.media_type === "media.imagefile" ? displayImage : displayVideo}
      </div>
    </>
  );
};

export default DisplayMedia;
