import React, { useState, useEffect } from "react";

import "./Cards.css";
import Card from "../../components/Card/Card";

const Cards = (props) => {
  const [selection, setSelection] = useState("project");
  const [projectList, setProjectList] = useState([]);
  const [acceptedProjects, setAcceptedProject] = useState([]);
  const [billTotalCost, setBillTotalCost] = useState(0);
  const [cartList, setCartList] = useState([]);
  const [marketTotalCost, setMarketTotalCost] = useState(0);

  useEffect(() => {
    setProjectList(props.project_list);
    setAcceptedProject(props.accepted_project);
    setBillTotalCost(props.bill_total_cost)
    setCartList(props.cart_list)
    const market_sum = cartList.reduce((sum, item) => sum + item.price, 0);
    setMarketTotalCost(market_sum);
  }, [props.project_list, props.accepted_project, props.bill_total_cost, props.cart_list, cartList]);

  return (
    <div
      className="cards-section d-flex flex-column flex-lg-row justify-content-between"
      onClick={() => props.setSelected(selection)}
    >
      <Card
        card_selection={"project"}
        title_value={projectList.length}
        title={"Project"}
        icon="cpu-fill"
        first_detail_value={acceptedProjects.length}
        first_detail={"Accepted"}
        sec_detail_value={projectList.length - acceptedProjects.length}
        sec_detail={"Pending"}
        setSelection={setSelection}
      />
      <Card
        card_selection={"market"}
        isCenterCard={true}
        title_value={cartList.length}
        title={"Market"}
        icon="cart3"
        sec_detail_value={
          new Intl.NumberFormat("en-Tz", {
            style: "currency",
            currency: "TZS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(marketTotalCost) + "/="
        }
        sec_detail={"Total price"}
        setSelection={setSelection}
      />
      <Card
        card_selection={"bill"}
        title_value={projectList.length}
        title={"Bills"}
        icon="currency-exchange"
        sec_detail_value={
          new Intl.NumberFormat("en-Tz", {
            style: "currency",
            currency: "TZS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(billTotalCost) + "/="
        }
        sec_detail={"Total cost"}
        setSelection={setSelection}
      />
    </div>
  );
};

export default Cards;
