import React from "react";
import { useNavigate } from "react-router-dom";

import "./Response.css";

const Cancel = () => {
  const navigate = useNavigate();
  return (
    <div className="response d-flex flex-column" onClick={() => navigate(-1)}>
      <div className="response-container">
        <div className="icon victech-blue">
          <i className="bi bi-dash-circle" />
        </div>
        <h1>CANCELED</h1>
      </div>
    </div>
  );
};

export default Cancel;
