import React from "react";
import { Link } from "react-router-dom";

import data from "../assets/data";

const TeamMember = (props) => {
  const value = props.properties;

  return (
    <div className="col-lg-4 col-md-6">
      <div className="member" data-aos="zoom-in">
        <div className="pic">
          <img
            src={`${data.backend_url}/media-files/image/admin/${value.picture}`}
            loading="lazy"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="member-info">
          <h4>{value.name}</h4>
          <span>{value.position}</span>
          <div className="social">
            <Link to={value.whatsapp_link}>
              <i className="bi bi-whatsapp"></i>
            </Link>
            <Link to={value.facebook_link}>
              <i className="bi bi-facebook"></i>
            </Link>
            <Link to={value.twitter_link}>
              <i className="bi bi-twitter-x"></i>
            </Link>
            <Link to={value.instagram_link}>
              <i className="bi bi-instagram"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
