import React from "react";
import "./Loading.css";

const Loading = (props) => {
  return props.trigger ? (
    <div className="loading">
      <div className="loading-container">
        <div className="loader"></div>
        <h2>Loading...</h2>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Loading;
